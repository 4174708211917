/* istanbul ignore file */
import React, { FC } from 'react'
import { Checkbox, FormControlLabel } from '@moonpig/launchpad-forms'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Flex, Text } from '@moonpig/launchpad-components'
import { StyledLabel } from './StyledLabel'
import { Filter } from '../../../../services/types/services'

const StyledFilterOption = styled(FormControlLabel)<{ indentation?: number }>`
  display: flex;
  align-items: center;

  ${({ indentation }) =>
    s({
      pl: indentation || 3,
      pr: 6,
      py: 3,
      height: '56px',
      borderBottom: 1,
      borderColor: 'colorBorder04',
      color: 'colorTextLabel',
    })}

  &:hover {
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBlack05',
    })}
  }

  &:active {
    ${s({
      bgcolor: 'colorBackground03',
      borderColor: 'colorBlack10',
    })}
  }

  &:focus,
  &:focus-visible {
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBlack05',
      color: 'colorInteractionFocusIndicator',
    })}
  }
`

const StyledText = styled(Text)`
  ${s({
    typography: 'typeBodyCaption',
    color: 'colorBlack40',
  })}
`

type Props = {
  filter: Filter
  indentation?: number
  onClick: (filter: Filter, select: boolean) => void
}

export const FilterOption: FC<Props> = ({ filter, indentation, onClick }) => {
  const { id, label, isSelected, count } = filter

  return (
    <StyledFilterOption
      data-testid="filter-option"
      key={id}
      indentation={indentation}
      label=""
    >
      <Checkbox
        onChange={() => onClick(filter, !isSelected)}
        checked={!!isSelected}
        value={id}
      />
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <StyledLabel label={label} isSelected={!!isSelected} />
        {count ? <StyledText>{count}</StyledText> : null}
      </Flex>
    </StyledFilterOption>
  )
}
