import React, { FC, useEffect, useReducer, useMemo } from 'react'
import { useStoreId } from '@moonpig/web-core-stores'
import {
  tryGetLocalStorageWithExpiry,
  trySetLocalStorageWithExpiry,
} from '@moonpig/web-shared-utils'
import { addToRecentlyViewed, initialise } from './actions'
import { reducer } from './Reducer'
import { dispatcherContext, InitialState, stateContext } from './Context'
import {
  deserialiseRecentlyViewed,
  serialiseRecentlyViewed,
} from './serialisation'
import type { Dispatcher } from './types'

export const RecentlyViewedProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const storeId = useStoreId()
  const [state, dispatch] = useReducer(reducer, InitialState)
  const recentlyViewedKey = `mnpg_recentlyviewed_${storeId}`

  const actions = useMemo<Dispatcher>(
    () => ({
      addToRecentlyViewed: params => {
        dispatch(addToRecentlyViewed({ ...params, region: storeId }))
      },
    }),
    [storeId],
  )

  useEffect(() => {
    const recentlyViewedProductsData =
      tryGetLocalStorageWithExpiry(recentlyViewedKey)

    const recentlyViewedProducts = deserialiseRecentlyViewed(
      recentlyViewedProductsData,
    )

    dispatch(
      initialise({
        recentlyViewedProducts,
      }),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.recentlyViewedProducts.length) {
      trySetLocalStorageWithExpiry(
        recentlyViewedKey,
        serialiseRecentlyViewed(state.recentlyViewedProducts),
      )
    }
  }, [state, recentlyViewedKey])

  return (
    <dispatcherContext.Provider value={actions}>
      <stateContext.Provider value={state}>{children}</stateContext.Provider>
    </dispatcherContext.Provider>
  )
}
