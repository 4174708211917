import { StateCreator } from 'zustand'
import { ProductsState } from './types'
import { ContextState } from '../context'
import { FiltersState } from '../filters'
import { SearchFilter } from '../../../services'
import { GALLERY_PAGE_SIZE } from '../../../constants'
import { SharedState } from '../shared'

export const createProductsSlice: StateCreator<
  ProductsState &
    Partial<ContextState> &
    Partial<FiltersState> &
    Partial<SharedState>
> = (set, get) => ({
  initialOffset: 0,
  topPage: 1,
  bottomPage: 1,
  lastLoadedPage: 1,
  results: {
    totalCount: 0,
    products: [],
    suggestions: [],
    departments: [],
    page: 1,
    supportedSortOrders: [],
    totalClickRankDocumentCount: 0,
  },
  updateResults: results => {
    const { toggleLoading } = get()

    toggleLoading?.(false)

    set(() => ({ results }))
  },
  setInitialOffset: initialOffset => set(() => ({ initialOffset })),
  resetInitialOffset: () => set(() => ({ initialOffset: 0 })),
  querySearch: null,
  queryProducts: async ({
    newPage,
    isLoadMore,
    isPrevious,
    filters,
    sortOrder,
    filteringEvent,
  }) => {
    const {
      querySearch,
      results,
      toggleLoading,
      preAppliedFilters,
      selectedFilters,
      sortValue,
      searchContext,
    } = get()

    const queryFilters = filters || selectedFilters

    /* istanbul ignore next */
    if (!querySearch || !searchContext) {
      return null
    }

    const { selectedSuggestion } = searchContext

    if (!isLoadMore) {
      toggleLoading?.()
    }

    const searchResponse = await querySearch({
      newPage,
      filters: selectedSuggestion?.facetKey
        ? [
            ...(queryFilters
              ? queryFilters.map(f => ({
                  group: f.parent,
                  key: f.id,
                }))
              : /* istanbul ignore next */ ([] as SearchFilter[])),
            {
              group: selectedSuggestion.group,
              key: selectedSuggestion.facetKey,
            },
          ]
        : [
            ...(preAppliedFilters || /* istanbul ignore next */ []),
            ...(queryFilters
              ? queryFilters.map(f => ({
                  group: f.parent,
                  key: f.id,
                }))
              : /* istanbul ignore next */ ([] as SearchFilter[])),
          ],
      sortOrder: sortOrder || sortValue,
    })

    let updatedProducts
    if (isLoadMore) {
      updatedProducts = isPrevious
        ? [...searchResponse.products, ...results.products]
        : [...results.products, ...searchResponse.products]
    } else {
      updatedProducts = searchResponse.products
    }

    const updatedResults = {
      ...searchResponse,
      products: updatedProducts,
    }

    toggleLoading?.(false)

    set(() => ({
      results: updatedResults,
      selectedFilters: queryFilters,
      filteringEvent,
      sortValue: sortOrder || sortValue,
    }))
  },
  loadMoreProducts: async isPrevious => {
    const { results, queryProducts, topPage, bottomPage, loading } = get()

    if (loading) {
      return true
    }

    if (
      results.products.length >= results.totalCount ||
      results.totalCount <= GALLERY_PAGE_SIZE
    ) {
      return false
    }

    const newPage = isPrevious ? topPage - 1 : bottomPage + 1
    await queryProducts({ newPage, isLoadMore: true, isPrevious })

    set(() => ({
      lastLoadedPage: isPrevious ? topPage - 1 : bottomPage + 1,
      bottomPage: isPrevious ? bottomPage : bottomPage + 1,
      topPage: isPrevious ? topPage - 1 : topPage,
    }))

    return true
  },
})
