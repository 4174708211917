import React from 'react'
import { Item } from '@radix-ui/react-dropdown-menu'
import { Box, Flex } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Accordion } from '@radix-ui/react-accordion'
import {
  ApplicableFilter,
  FilterCategory,
  FilterItem,
} from '../../../services/types/services'
import { FilterSource } from '../../../types'
import { mapFilterItem } from '../../../utils/GalleryFiltering/mappings'
import { shouldShowClearButton } from '../../../utils/GalleryFiltering/shouldShowClearButton'
import { ClearButton } from '../../ClearButton'
import { FilterCategory as FilterCategoryComponent } from '../../FiltersMenu/components'

const StyledFooterContainer = styled(Box)`
  justify-content: center;
  ${s({
    bgcolor: 'colorBackground01',
    borderTop: 1,
    borderColor: 'colorBorder03',
    position: 'sticky',
    bottom: 0,
  })}
`

const StyledItem = styled(Item)`
  &:focus-visible {
    outline: none;
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBlack05',
      color: 'colorInteractionFocusIndicator',
    })}
  }
`

const StyledAccordionItem = styled(Item)`
  width: 100%;
`

type Props = {
  filterCategory: FilterCategory
  selectedFilters: ApplicableFilter[]
  isMobile: boolean
  resultsButton: JSX.Element
  clearFilters: ({
    source,
    category,
    type,
  }: {
    source: FilterSource
    category?: FilterCategory
    type?: 'filters' | 'sort'
  }) => void
  viewResultsButtonRef: React.RefObject<HTMLDivElement>
}

export const createMenuFooter = ({
  filterCategory,
  selectedFilters,
  isMobile,
  resultsButton,
  clearFilters,
  viewResultsButtonRef,
}: Props) => {
  const showClearButton = shouldShowClearButton({
    category: filterCategory,
    selectedFilters,
  })

  const handleClearButtonKeyDown = (event: React.KeyboardEvent) => {
    if (['Enter', ' '].includes(event.key)) {
      event.preventDefault()
      clearFilters({
        category: filterCategory,
        type: 'filters',
        source: FilterSource.QuickFilters,
      })
    }
    viewResultsButtonRef.current?.focus()
  }

  return isMobile ? (
    <StyledFooterContainer>{resultsButton}</StyledFooterContainer>
  ) : (
    <StyledFooterContainer display="flex" alignItems="center">
      {showClearButton && (
        <Flex width={1 / 3} justifyContent="center" alignItems="center">
          <Item
            data-testid="quick-filter-dropdown-item-clear-button"
            onSelect={(event: Event) => event.preventDefault()}
            onKeyDown={handleClearButtonKeyDown}
          >
            <ClearButton
              level={1}
              category={filterCategory}
              source={FilterSource.QuickFilters}
              hide={false}
            />
          </Item>
        </Flex>
      )}
      <Flex
        width={showClearButton ? 2 / 3 : 1}
        justifyContent="center"
        alignItems="center"
      >
        <StyledAccordionItem
          ref={viewResultsButtonRef}
          data-testid="quick-filter-dropdown-item-view-results-button"
        >
          {resultsButton}
        </StyledAccordionItem>
      </Flex>
    </StyledFooterContainer>
  )
}

export const createMenuItems = (
  filterItems: FilterItem[],
  toggleFilter: ({
    filter,
    select,
    source,
  }: {
    filter: ApplicableFilter
    select: boolean
    source: FilterSource
  }) => void,
  onFilterItemClick: (filterItem: FilterItem, select?: boolean) => void,
  isMobile: boolean,
) => {
  const ItemElement: React.ElementType = isMobile ? Box : StyledItem

  const handleKeyDownFilterSelection = (
    event: React.KeyboardEvent,
    filterItem: ApplicableFilter,
  ) => {
    switch (event.key) {
      case 'Enter':
      case ' ': // Space
        toggleFilter({
          filter: filterItem,
          select: !filterItem.isSelected,
          source: FilterSource.QuickFilters,
        })
        break
    }
  }

  const handleKeyDownCategorySelection = (event: React.KeyboardEvent) => {
    if (
      event.key === 'Enter' ||
      (event.key === ' ' && event.target === event.currentTarget)
    ) {
      event.preventDefault()
      event.stopPropagation()
      const categoryAccordionButton =
        event.currentTarget.querySelector('button')
      if (categoryAccordionButton) {
        categoryAccordionButton.click()
      }
    }
  }

  return filterItems.map(filterItem => {
    if (filterItem.__typename === 'Filter') {
      return (
        <ItemElement
          data-testid={`quick-filter-dropdown-item-${filterItem.id}`}
          key={filterItem.id || /* istanbul ignore next */ filterItem.label}
          onSelect={(event: Event) => {
            event.preventDefault()
          }}
          onKeyDown={(event: React.KeyboardEvent) => {
            handleKeyDownFilterSelection(event, filterItem)
          }}
          style={{ outline: 'none' }}
        >
          {mapFilterItem({
            filterItem,
            onClick: () => {
              toggleFilter({
                filter: filterItem,
                select: !filterItem.isSelected,
                source: FilterSource.QuickFilters,
              })
            },
          })}
        </ItemElement>
      )
    }

    if (filterItem.__typename === 'FilterCategory') {
      return filterItem.children.length ? (
        <Accordion key={filterItem.id} type="multiple">
          <ItemElement
            data-testid={`quick-filter-dropdown-item-${filterItem.id}-category`}
            onSelect={(event: Event) => {
              event.preventDefault()
            }}
            onKeyDown={handleKeyDownCategorySelection}
          >
            <FilterCategoryComponent
              filter={filterItem}
              onClick={onFilterItemClick}
              key={`${filterItem.id}-category`}
              level={1}
              isDropdown
            />
          </ItemElement>
        </Accordion>
      ) : null
    }

    if (filterItem.__typename === 'FilterHeader') {
      return (
        <ItemElement key={filterItem.label} disabled>
          {mapFilterItem({
            filterItem,
            onClick: /* istanbul ignore next */ () => {},
          })}
        </ItemElement>
      )
    }

    /* istanbul ignore next */
    return null
  })
}
