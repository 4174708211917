import React, { FC } from 'react'
import { Toggle } from '@moonpig/launchpad-forms'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  IconSystemEdit,
  IconSystemImage,
  IconSystemNumberField,
} from '@moonpig/launchpad-assets'
import {
  FilterToggle as FilterToggleType,
  InvertedFilterToggle,
} from '../../../../services/types/services'
import { FILTERS_MENU_HEADER_HEIGHT_PX } from '../../constants'

const ICON_SIZE = 24

const StyledFilterToggle = styled(Flex)`
  min-height: ${FILTERS_MENU_HEADER_HEIGHT_PX};
  cursor: pointer;
  ${s({
    justifyContent: 'space-between',
    py: 4,
    pl: 6,
    pr: 4,
    alignItems: 'center',
  })}
`

const StyledIconImage = styled.div`
  ${s({
    color: 'colorBlack50',
    width: `${ICON_SIZE}px`,
    height: `${ICON_SIZE}px`,
    mr: 4,
  })}
`

const StyledLabel = styled.label`
  display: flex;
  cursor: pointer;
  ${s({
    color: 'colorTextLabel',
  })}
`

const StyledLabelText = styled(Text)`
  ${s({
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopBodyLabel' },
    fontWeight: 'bold',
  })}
`

type Props = {
  /** Filter Toggle object */
  filter: FilterToggleType | InvertedFilterToggle
  /** Aria label for the filter */
  ariaLabel?: string
  showIcon?: boolean
  isQuickFilter?: boolean
  /** Function to call when the filter is toggled */
  onChange: (
    filter: FilterToggleType | InvertedFilterToggle,
    select: boolean,
  ) => void
}

const FILTER_ICONS: {
  [key: string]: React.JSX.Element
} = {
  IconSystemImage: <IconSystemImage />,
  IconSystemEdit: <IconSystemEdit />,
  IconSystemNumberField: <IconSystemNumberField />,
}

export const FilterToggle: FC<Props> = ({
  filter,
  ariaLabel,
  onChange,
  showIcon = true,
  isQuickFilter = false,
}) => {
  const { icon, label, isSelected, __typename, id } = filter
  const labelIcon = icon && FILTER_ICONS[icon]
  const inverted = __typename === 'FilterInvertedToggle'

  return (
    <StyledFilterToggle
      aria-label={ariaLabel || `${label} filter toggle`}
      onClick={() => onChange(filter, !isSelected)}
      data-testid="filter-toggle"
    >
      <StyledLabel htmlFor={id}>
        {labelIcon && showIcon && (
          <StyledIconImage aria-hidden>
            {React.cloneElement(labelIcon, {
              width: ICON_SIZE,
              height: ICON_SIZE,
            })}
          </StyledIconImage>
        )}
        <StyledLabelText>{label}</StyledLabelText>
      </StyledLabel>

      <Box style={{ scale: isQuickFilter ? '0.8' : '1' }}>
        <Toggle
          onChange={() => {}}
          checked={inverted ? !isSelected : !!isSelected}
          value={id}
          id={id}
          tabIndex={isQuickFilter ? -1 : 0}
        />
      </Box>
    </StyledFilterToggle>
  )
}
