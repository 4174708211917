import { Box } from '@moonpig/launchpad-components'
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useFlag } from '@moonpig/web-explore-flags'
import { Loader } from './Loader'
import { LoadMoreType, PageLoadState } from '../types'
import { GALLERY_LOAD_MORE_THRESHOLD, GALLERY_PAGE_SIZE } from '../../constants'
import { scrollPageDown } from './scrollPageDown'
import { useSearchStore } from '../../store/SearchStore'

type InfiniteScrollProps = {
  loaded: boolean
  totalCount: number
  loadState: PageLoadState
  products: { productIndex?: number }[]
  loadCallback: (loadMoreType: LoadMoreType) => Promise<PageLoadState>
  setShouldInfiniteScrollUp: (shouldInfiniteScroll: boolean) => void
  setShouldInfiniteScrollDown: (shouldInfiniteScroll: boolean) => void
}

const useScrollDownOnPageUp = ({
  loaded,
  products,
}: {
  loaded: boolean
  products: { productIndex?: number }[]
}) => {
  const topPage = useSearchStore(store => store.topPage)
  const shouldUseSearchStore = useFlag('search-state-management-refactor')
  const scrollRef = useRef<HTMLDivElement>(null)

  const scrollDownOnePage = useCallback(() => {
    scrollPageDown(scrollRef.current as HTMLElement, products.length)
  }, [products.length])

  const [topItemIndex, setTopItemIndex] = useState(
    products[0]?.productIndex || 0,
  )

  useEffect(() => {
    if (shouldUseSearchStore) {
      if (
        (products[0]?.productIndex || 0) <
        (topPage - 1) * GALLERY_PAGE_SIZE
      ) {
        scrollDownOnePage()
      }
    } else if ((products[0]?.productIndex || 0) < topItemIndex) {
      scrollDownOnePage()
      setTopItemIndex(products[0]?.productIndex || 0)
    }
  }, [
    products,
    loaded,
    topItemIndex,
    scrollDownOnePage,
    shouldUseSearchStore,
    topPage,
  ])

  return { scrollRef }
}

export const InfiniteScroll: FC<PropsWithChildren<InfiniteScrollProps>> = ({
  children,
  loaded,
  totalCount,
  loadState,
  products,
  loadCallback,
  setShouldInfiniteScrollUp,
  setShouldInfiniteScrollDown,
}) => {
  const [maxItems, setMaxItems] = useState(
    GALLERY_LOAD_MORE_THRESHOLD * GALLERY_PAGE_SIZE,
  )

  const showLoadMore = products.length >= maxItems
  const isLastItemLoaded =
    products[products.length - 1]?.productIndex === totalCount - 1
  const isFirstItemLoaded = (products[0]?.productIndex || 0) === 0

  const { scrollRef } = useScrollDownOnPageUp({ loaded, products })

  useEffect(() => {
    setShouldInfiniteScrollUp(
      loaded &&
        products.length + GALLERY_PAGE_SIZE <= maxItems &&
        !isFirstItemLoaded,
    )

    setShouldInfiniteScrollDown(
      loaded &&
        products.length + GALLERY_PAGE_SIZE <= maxItems &&
        !isLastItemLoaded,
    )
  }, [
    loaded,
    products,
    maxItems,
    setShouldInfiniteScrollUp,
    setShouldInfiniteScrollDown,
    isLastItemLoaded,
    isFirstItemLoaded,
    totalCount,
  ])

  const loadMore = useCallback(
    async (loadMoreType: LoadMoreType) => {
      const loadMoreProductsState = await loadCallback(loadMoreType)

      if (loadMoreProductsState !== PageLoadState.Error) {
        setMaxItems(maxItems + GALLERY_PAGE_SIZE * GALLERY_LOAD_MORE_THRESHOLD)
      }
    },
    [loadCallback, maxItems],
  )

  const onLoadMorePrevClicked = useCallback(async () => {
    await loadMore(LoadMoreType.CLICK_PREV)
  }, [loadMore])

  const onLoadMoreNextClicked = useCallback(async () => {
    await loadMore(LoadMoreType.CLICK)
  }, [loadMore])

  return (
    <Box mb={6}>
      <Box mb={6}>
        <Loader
          loading={loadState === PageLoadState.LoadingPrev}
          showLoadButton={
            (loadState === PageLoadState.Error || showLoadMore) &&
            !isFirstItemLoaded
          }
          loadMoreClicked={onLoadMorePrevClicked}
        />
      </Box>
      <Box ref={scrollRef} data-testid="product-grid-wrapper">
        {children}
      </Box>
      <Box mt={6} mb={6}>
        <Loader
          loading={loadState === PageLoadState.LoadingNext}
          showLoadButton={
            (loadState === PageLoadState.Error || showLoadMore) &&
            !isLastItemLoaded
          }
          loadMoreClicked={onLoadMoreNextClicked}
        />
      </Box>
    </Box>
  )
}
