import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Alert, Box, Heading } from '@moonpig/launchpad-components'
import { useFindLocaleText } from '../../text-localisation/localeTextProvider'

const AlertContainer = styled(Box)`
  position: fixed;
  z-index: 2001;
  bottom: 20px;
  right: 20px;
  max-width: 430px;
  pointer-events: all;

  ${s({
    paddingX: 4,
    right: 0,
  })}

  animation: alert-fade-in 500ms;

  @keyframes alert-fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`
type FilterSelectionErrorProps = {
  onDismiss: () => void
}
export const FilterSelectionError: FC<FilterSelectionErrorProps> = ({
  onDismiss,
}) => {
  const localiseText = useFindLocaleText()
  return (
    <AlertContainer>
      <Alert
        variant="error"
        my={5}
        testId={'filter-selection-error-message'}
        onClickClose={onDismiss}
      >
        <Heading
          level="h4"
          mb={0}
          typography={{
            xs: 'typeMobileDisplay05',
            md: 'typeDesktopDisplay06',
          }}
        >
          {localiseText('find.something_went_wrong')}
        </Heading>

        {localiseText('find.filter_selection_error_message')}
      </Alert>
    </AlertContainer>
  )
}
