import React, { forwardRef } from 'react'
import { CarouselItem } from './CarouselItem'
import { ApplicableFilter } from '../../../services/types/services'
import { useFindLocaleText } from '../../../text-localisation'
import { FilterSource } from '../../../types'
import { useSearchStore } from '../../../store/SearchStore'
import { Chip } from './Chip'

type FilterChipProps = {
  filter: ApplicableFilter
  select: boolean
}

export const FilterChipVariant = forwardRef<HTMLLIElement, FilterChipProps>(
  ({ filter, select }, ref) => {
    const localiseText = useFindLocaleText()
    const toggleFilter = useSearchStore(store => store.toggleFilter)

    const label = select
      ? localiseText('find.apply_facet_group_filter_facet_label', {
          group: filter.parent,
          label: filter.label.toLowerCase(),
        })
      : localiseText('find.remove_facet_group_filter_facet_label', {
          group: filter.parent,
          label: filter.label,
        })

    return (
      <CarouselItem ref={ref}>
        <Chip
          dismissible
          selected={!select}
          onClick={() =>
            toggleFilter({
              filter,
              select,
              source: FilterSource.NextBestAction,
            })
          }
          aria-label={label}
        >
          {filter.label}
        </Chip>
      </CarouselItem>
    )
  },
)
