import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  IconSystemChevronDown,
  IconSystemChevronUp,
} from '@moonpig/launchpad-assets'
import { useFindLocaleText } from '../../../text-localisation'

const StyledIcon = styled.span`
  ${s({
    display: 'flex',
    position: 'relative',
    alignSelf: 'center',
    width: '24px',
    height: '24px',
    ml: 4,
  })}
`

const StyledButton = styled.button<{ count: boolean }>`
  transition: none;
  cursor: pointer;

  ${({ count }) =>
    s({
      position: 'relative',
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      my: { xs: 5, md: 6 },
      mx: 3,
      p: 5,
      height: '48px',
      typography: 'typeButtonLabel',
      borderRadius: 2,
      borderColor: count ? 'colorInteractionSelectedState' : 'colorBorder03',
      border: 2,
      bgcolor: 'colorBackground01',
      color: count ? 'colorInteractionSelectedState' : 'colorInteractionIcon',
      boxShadow: 0,
    })}

  &:hover {
    text-decoration: none;
    outline: none;
    ${({ count }) =>
      s({
        boxShadow: 0,
        borderColor: count ? 'colorInteractionSelectedState' : 'colorBlack30',
        bgcolor: count ? 'colorBackground03' : 'colorBorder04',
        color: count ? 'colorInteractionSelectedState' : 'colorInteractionIcon',
      })}
  }

  &:focus-visible {
    text-decoration: none;
    outline: none;
    ${({ count }) =>
      s({
        boxShadow: 5,
        borderColor: count ? 'colorInteractionSelectedState' : 'colorBlack30',
        bgcolor: count ? 'colorBackground03' : 'colorBorder04',
      })};
  }
`

type Props = {
  /** The label for the button */
  label: string
  /** The callback function to call when the button is clicked */
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Whether the dropdown is open or not */
  isOpen: boolean
  /** The number of filters applied */
  count: number
} & { ref?: React.Ref<HTMLButtonElement> }

export const DropDownButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const localiseText = useFindLocaleText()

    const trailingIcon = props.isOpen ? (
      <IconSystemChevronUp />
    ) : (
      <IconSystemChevronDown />
    )

    const ariaLabel = localiseText(
      props.isOpen
        ? 'find.filters_menu_panel.close'
        : 'find.filters_menu_panel.open',
      props.label,
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      props.onClick(event)
    }

    return (
      <StyledButton
        aria-label={ariaLabel}
        {...props}
        ref={ref}
        onClick={handleClick}
        count={props.count > 0}
      >
        {props.label}
        <StyledIcon>{trailingIcon}</StyledIcon>
      </StyledButton>
    )
  },
)
