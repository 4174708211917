import React, { FC, memo, useCallback } from 'react'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { getParentDepartment } from '@moonpig/web-core-utils'
import {
  trackUIEvent,
  createProductViewedEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { Box, Flex } from '@moonpig/launchpad-components'
import {
  ProductClickedEvent,
  AddedToBasketEvent,
  Status,
  PromoTileData,
  calculateRowNumber,
  useNumberOfTilesPerRow,
} from '@moonpig/web-shared-products'
import { getDeviceType } from '@moonpig/web-shared-utils'
import { SHOW_RUDE_TOGGLE } from '@moonpig/web-core-brand/config'
import { useStoreId } from '@moonpig/web-core-stores'
import { useFlag } from '@moonpig/web-explore-flags'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { LoadMoreType } from '../types'
import { loadMoreGAEventDetails } from '../../analytics/GAEvents'
import { ProductGridWithPaging, GalleryProduct } from '../ProductGridWithPaging'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { useFindLocaleText } from '../../text-localisation'
import { SearchFiltersFacet } from '../../pages/GalleryPage/types'
import { getSortByOptions, useSetUIHCookie } from '../../utils'
import { useSearchStore } from '../../store/SearchStore'
import { RudeToggle, SortButton } from '../FiltersMenu'
import { InvertedFilterToggle } from '../../services/types/services'
import { SearchContext } from '../../store/slices/shared'

type ProductGridWithTrackingProps = {
  totalCount: number
  pageTitle: string
  metaTitle: string
  searchContext: SearchContext
  products: GalleryProduct[]
  loadMoreProducts?: (loadMoreType: LoadMoreType) => Promise<boolean>
  presetFacets: SearchFiltersFacet[]
  experiments?: { [key: string]: string } | undefined
  isInternalUser?: boolean
  onAddedToBasket: (e: AddedToBasketEvent) => void
  pageType: string
  customerId?: string
  isZeroSearchResults?: boolean
  groupCardProject?: string
  promoTile?: PromoTileData
}

export const ProductGridWithTrackingComponent: FC<
  ProductGridWithTrackingProps
> = ({
  totalCount,
  metaTitle,
  searchContext,
  products,
  loadMoreProducts,
  experiments,
  onAddedToBasket,
  isInternalUser = false,
  pageType,
  customerId,
  isZeroSearchResults = false,
  groupCardProject,
  promoTile,
}) => {
  const shouldUseSearchStore = useFlag('search-state-management-refactor')
  const [{ loading }] = useProductListingPageContext()
  const storeId = useStoreId()
  const { searchTerm, departments } = searchContext
  const localiseText = useFindLocaleText()
  const { setUIHCookie } = useSetUIHCookie()
  const toggleFiltersMenu = useSearchStore(store => store.toggleFiltersMenu)
  const sortValue = useSearchStore(store => store.sortValue)
  const filtersMenuOpen = useSearchStore(store => store.filtersMenuOpen)
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const filters = useSearchStore(store => store.filters)
  const preAppliedFilters = useSearchStore(store => store.preAppliedFilters)
  const storeLoadingFlag = useSearchStore(store => store.loading)
  const loadingProducts = shouldUseSearchStore ? storeLoadingFlag : loading
  const useNewGalleryUX =
    useExperimentString('search-product-tile-ux', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  const productsLoading = filtersMenuOpen
    ? loadingProducts || storeLoadingFlag
    : loadingProducts

  const onProductClicked = useCallback(
    async ({ product }: ProductClickedEvent) => {
      setUIHCookie({ product })
    },
    [setUIHCookie],
  )

  const parentDepartment = getParentDepartment(departments[0])

  const isCards = parentDepartment === 'ALL_CARDS'

  const currentTilesPerRow = useNumberOfTilesPerRow()

  const isQuickFiltersExperiment =
    useExperimentString('search-use-dropdown-filters-v2', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  const onProductFirstClick = useCallback(
    async ({ product, index }: ProductClickedEvent) => {
      const { mnpg_ui_events_api_correlation_id: correlationId } =
        getBrowserCookies()
      if (process.env.API_URL) {
        try {
          await trackUIEvent(
            createProductViewedEvent({
              productId: product.id,
              searchTerm,
              department: departments,
              correlationId,
              filters: [
                ...selectedFilters.map(x => ({
                  facetKey: x.id,
                })),
                ...preAppliedFilters.map(x => ({
                  facetKey: x.key,
                })),
              ],
              store: storeId.toUpperCase(),
              ...(experiments && { metaData: { experiments } }),
              source: getDeviceType(),
              customerId,
              rowNumber: calculateRowNumber(
                (index ?? 0) + 1,
                currentTilesPerRow,
              ),
            }),
          )
        } catch (error) {
          // @eslint-disable-next-line @typescript-eslint/no-empty
        }
      }
    },
    [
      searchTerm,
      departments,
      selectedFilters,
      preAppliedFilters,
      storeId,
      experiments,
      customerId,
      currentTilesPerRow,
    ],
  )

  const onAddedToBasketWithTracking = React.useCallback(
    (e: AddedToBasketEvent) => {
      onAddedToBasket({
        ...e,
        componentName: 'product grid',
      })
    },
    [onAddedToBasket],
  )

  const onLoadMoreCallback = React.useCallback(
    async (type: LoadMoreType) => {
      if (type === LoadMoreType.CLICK) {
        trackGAEvent(loadMoreGAEventDetails(products.length))
      }
      return (
        (loadMoreProducts && loadMoreProducts(type)) ||
        /* istanbul ignore next */ true
      )
    },
    [loadMoreProducts, products.length],
  )

  const notRudeFilterFromStore = filters.find(
    f => f.__typename === 'FilterInvertedToggle' && f.id === 'not-rude',
  ) as InvertedFilterToggle

  const enableRudeToggle =
    SHOW_RUDE_TOGGLE && isCards && !!notRudeFilterFromStore

  const sortByOptions = getSortByOptions({
    isCards,
    localiseText,
  })

  return (
    <Box>
      <Status
        loading={{
          status: productsLoading,
          fixed: false,
          zIndex: 999,
        }}
      />
      {isQuickFiltersExperiment ? (
        enableRudeToggle && (
          <Flex
            justifyContent="flex-end"
            pr={useNewGalleryUX ? { xs: 0, md: 4 } : { xs: 3, md: 7, xxl: 9 }}
            mb={4}
          >
            <RudeToggle filter={notRudeFilterFromStore} />
          </Flex>
        )
      ) : (
        <Flex
          justifyContent="space-between"
          pl={useNewGalleryUX ? { xs: 6 } : { xs: 6, md: 7, lg: 10, xxl: 12 }}
          pr={useNewGalleryUX ? { xs: 0, md: 4 } : { xs: 3, md: 7, xxl: 9 }}
          flexDirection={isCards ? 'row' : 'row-reverse'}
          mb={4}
        >
          {!isZeroSearchResults && (
            <SortButton
              onClick={() => toggleFiltersMenu('sort')}
              sortByLabel={localiseText('find.sort_by')}
              selectedOptionLabel={
                sortByOptions.find(sortOption => sortOption.value === sortValue)
                  ?.label || /* istanbul ignore next */ ''
              }
            />
          )}
          {enableRudeToggle && <RudeToggle filter={notRudeFilterFromStore} />}
        </Flex>
      )}

      <ProductGridWithPaging
        totalCount={totalCount}
        region={storeId}
        products={products}
        loadMoreProducts={onLoadMoreCallback}
        onProductClicked={onProductClicked}
        onProductFirstClick={onProductFirstClick}
        onAddToBasket={onAddedToBasketWithTracking}
        isInternalUser={isInternalUser}
        metaTitle={metaTitle}
        pageType={pageType}
        groupCardProject={groupCardProject}
        promoTile={promoTile}
      />
    </Box>
  )
}

export const ProductGridWithTracking = memo(ProductGridWithTrackingComponent)
