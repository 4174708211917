import React, { FC } from 'react'
import {
  Content as RadixAccordionContent,
  Item as RadixAccordionItem,
} from '@radix-ui/react-accordion'
import { styled, keyframes } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const ANIMATION_DURATION = 300
const REDUCED_MOTION_ANIMATION_DURATION = 1200

const slideDownAnimation = keyframes`
  0% { height: 0 }
  100% { height: var(--radix-accordion-content-height); }
`

const slideUpAnimation = keyframes`
  0% { height: var(--radix-accordion-content-height); }
  100% { height: 0; }
`

const StyledAccordionItem = styled(RadixAccordionItem)`
  overflow: hidden;

  ${s({
    borderColor: 'colorBorder04',
    borderBottom: 1,
  })}

  &[data-state='open'] {
    border-bottom: 0;
  }
`

const StyledAccordionContent = styled(RadixAccordionContent)`
  &[data-state='open'] {
    animation: ${slideDownAnimation} ${ANIMATION_DURATION}ms
      cubic-bezier(0.87, 0, 0.13, 1);

    @media (prefers-reduced-motion) {
      animation: ${slideDownAnimation} ${REDUCED_MOTION_ANIMATION_DURATION}ms
        linear;
    }
  }

  &[data-state='closed'] {
    animation: ${slideUpAnimation} ${ANIMATION_DURATION}ms
      cubic-bezier(0.87, 0, 0.13, 1);

    @media (prefers-reduced-motion) {
      animation: ${slideUpAnimation} ${REDUCED_MOTION_ANIMATION_DURATION}ms
        linear;
    }
  }
`

type Props = {
  heading: React.ReactNode
  value: string
}

export const AccordionContent: FC<React.PropsWithChildren<Props>> = ({
  heading,
  value,
  children,
}) => {
  return (
    <StyledAccordionItem value={value}>
      {heading}
      <StyledAccordionContent>
        {/* Additional div because animating an elements height with padding makes the animation laggy */}
        <div>{children}</div>
      </StyledAccordionContent>
    </StyledAccordionItem>
  )
}
