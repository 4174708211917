import { StateCreator } from 'zustand'
import { ContextState } from './types'
import { PageContext, SearchContext } from '../shared'

export const createContextSlice: StateCreator<ContextState> = set => ({
  pageContext: { title: '', type: '', router: null },
  searchContext: {
    departments: [],
    redirectedDepartment: undefined,
    searchTerm: '',
    correctedSearchTerm: '',
    selectedSuggestion: undefined,
    promotionId: undefined,
    sponsoredProducts: [],
  },
  createPageContext: (context: PageContext) =>
    set(() => ({ pageContext: context })),
  createSearchContext: (context: SearchContext) =>
    set(() => ({ searchContext: context })),
})
