import { StateCreator } from 'zustand'
import { SortOrder } from '@moonpig/web-explore-types-graphql'
import { ApplicableFilter, FilterItem } from '../../../services/types/services'
import { FilterAction } from '../../../types'
import {
  getPersistedFilters,
  getFiltersById,
  updateSelectedFilters,
  optimisticUpdatedFilterMapping,
  updateCategories,
  clearCategoryFilters,
  updateRoute,
} from '../../helpers'
import { FiltersState } from './types'
import { ContextState } from '../context'
import { ProductsState } from '../products'
import { SharedState } from '../shared'

export const createFiltersSlice: StateCreator<
  FiltersState &
    Partial<ContextState> &
    Partial<SharedState> &
    Partial<ProductsState>
> = (set, get) => ({
  filtersMenuOpen: false,
  filterSelectionError: false,
  filters: [],
  preAppliedFilters: [],
  selectedFilters: [],
  filteringEvent: undefined,
  selectedCategories: [],
  queryFilters: null,
  initialSortValue: SortOrder.POPULARITY,
  sortValue: SortOrder.POPULARITY,
  sortMenuOpen: false,
  optimisticRender: false,
  filterActionQueue: [],
  toggleFiltersMenu: source => {
    set(state => ({
      filtersMenuOpen: !state.filtersMenuOpen,
      selectedCategories: [],
      sortMenuOpen: source === 'sort',
    }))
  },
  toggleFiltersCategory: filtersCategory =>
    set(state => {
      const currentSelectedCategories = [...state.selectedCategories]
      const isCurrentCategory =
        filtersCategory.id ===
        currentSelectedCategories[currentSelectedCategories.length - 1]?.id
      if (isCurrentCategory) {
        currentSelectedCategories.pop()
      } else {
        currentSelectedCategories.push(filtersCategory)
      }
      return {
        selectedCategories: currentSelectedCategories,
      }
    }),
  optimisticallyApplyFilters: async filterActions => {
    const { selectedFilters, selectedCategories, filters } = get()

    let updatedSelectedFilters = selectedFilters
    let optimisticUpdatedFilters = filters

    filterActions.forEach(({ filter, select }) => {
      updatedSelectedFilters = updateSelectedFilters({
        selectedFilters: updatedSelectedFilters,
        newFilter: filter,
        select,
        allFilters: filters,
      })

      optimisticUpdatedFilters = optimisticUpdatedFilters.map(f =>
        optimisticUpdatedFilterMapping(f, filter, select),
      )
    })

    const updatedCategories = updateCategories(
      optimisticUpdatedFilters,
      selectedCategories.map(c => c.id),
    )

    set(() => {
      return {
        selectedFilters: updatedSelectedFilters,
        filters: optimisticUpdatedFilters,
        selectedCategories: updatedCategories,
        optimisticRender: true,
      }
    })
  },
  toggleFilter: async ({ filter, select, source }) => {
    const {
      selectedFilters,
      queryFilters,
      toggleLoading,
      preAppliedFilters,
      selectedCategories,
      pageContext: /* istanbul ignore next */ { router } = { router: null },
      optimisticallyApplyFilters,
      filters,
      filterActionQueue,
      queryProducts,
      shouldUseSearchStoreRefactor,
      setFilterError,
      clearFilterError,
    } = get()
    if (queryFilters) {
      try {
        toggleLoading?.()
        clearFilterError()
        const newfilterAction = { filter, select, source }

        set(() => {
          return {
            filterActionQueue: [...filterActionQueue, newfilterAction],
          }
        })

        const filterActions = get().filterActionQueue

        const shouldOptimisticallyApplyFilters = filterActions.length <= 1

        if (shouldOptimisticallyApplyFilters) {
          optimisticallyApplyFilters(filterActions)
        }
        const updatedSelectedFilters = filterActions.reduce(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (currentFilters, { filter, select }) => {
            return updateSelectedFilters({
              selectedFilters: currentFilters,
              newFilter: filter,
              select,
              allFilters: filters,
            })
          },
          selectedFilters,
        )

        const updatedFilters = await queryFilters([
          ...updatedSelectedFilters.map(f => ({
            key: f.id,
            group: f.parent,
            userApplied: true,
          })),
          ...preAppliedFilters,
        ])
        const updatedCategories = updateCategories(
          updatedFilters,
          selectedCategories.map(c => c.id),
        )
        updateRoute({ router, selectedFilters: updatedSelectedFilters })

        const latestFilterActions = get().filterActionQueue

        const filteringEvent = {
          action: select
            ? FilterAction.Add
            : /* istanbul ignore next */ FilterAction.Remove,
          source,
          filter,
        }

        if (shouldUseSearchStoreRefactor) {
          await queryProducts?.({
            newPage: 1,
            isLoadMore: false,
            isPrevious: false,
            filters: updatedSelectedFilters,
            filteringEvent,
          })
        }

        toggleLoading?.(false)

        set(() => {
          return {
            filters: updatedFilters,
            selectedFilters: updatedSelectedFilters,
            select,
            filteringEvent,
            selectedCategories: updatedCategories,
            optimisticRender: false,
            filterActionQueue: latestFilterActions.filter(
              latestAction =>
                !filterActions.some(
                  action =>
                    latestAction.filter === action.filter &&
                    latestAction.select === action.select &&
                    latestAction.source === action.source,
                ),
            ),
          }
        })
      } catch {
        toggleLoading?.(false)
        setFilterError()
        set(() => {
          return {
            selectedFilters,
            selectedCategories,
            filters,
            optimisticRender: false,
          }
        })
      }
    }
  },
  clearFilters: async ({ category, type, source }) => {
    const {
      queryFilters,
      toggleLoading,
      preAppliedFilters,
      selectedFilters,
      pageContext: /* istanbul ignore next */ { router } = { router: null },
      filters,
      queryProducts,
      shouldUseSearchStoreRefactor,
      initialSortValue,
      sortValue,
    } = get()
    let updatedFilters: FilterItem[] | null = null
    let updatedSelectedFilters: ApplicableFilter[] | null = null

    const filteringEvent = {
      action: category ? FilterAction.Clear : FilterAction.ClearAll,
      filter: category,
      source,
    }

    if (queryFilters) {
      if (type !== 'sort') {
        toggleLoading?.()
        const persistedFilters = getFiltersById({
          filterIds: getPersistedFilters(),
          allFilters: filters,
        })
        updatedSelectedFilters = category
          ? clearCategoryFilters(category, [...selectedFilters])
          : persistedFilters
        updatedFilters = await queryFilters([
          ...preAppliedFilters,
          ...updatedSelectedFilters.map(f => ({
            key: f.id,
            group: f.parent,
            userApplied: true,
          })),
        ])
        updateRoute({ router, selectedFilters: updatedSelectedFilters })

        if (shouldUseSearchStoreRefactor) {
          await queryProducts?.({
            newPage: 1,
            isLoadMore: false,
            isPrevious: false,
            filters: updatedSelectedFilters,
            filteringEvent,
          })

          toggleLoading?.(false)
        }
      } else if (shouldUseSearchStoreRefactor) {
        await queryProducts?.({
          newPage: 1,
          isLoadMore: false,
          isPrevious: false,
          sortOrder: category ? sortValue : initialSortValue,
          filteringEvent,
        })

        toggleLoading?.(false)
      }
      set(state => {
        return {
          filters: updatedFilters || filters,
          selectedFilters: updatedSelectedFilters || selectedFilters,
          filteringEvent: {
            action: category ? FilterAction.Clear : FilterAction.ClearAll,
            filter: category,
            source,
          },
          selectedCategories: updatedFilters
            ? updateCategories(
                updatedFilters,
                state.selectedCategories.map(c => c.id),
              )
            : state.selectedCategories,
          sortValue: category ? state.sortValue : state.initialSortValue,
        }
      })
    }
  },
  setFilterError: () => set(() => ({ filterSelectionError: true })),
  clearFilterError: () => set(() => ({ filterSelectionError: false })),
  sortBy: async value => {
    const { queryProducts, shouldUseSearchStoreRefactor, toggleLoading } = get()

    if (shouldUseSearchStoreRefactor) {
      toggleLoading?.()
      await queryProducts?.({
        newPage: 1,
        isLoadMore: false,
        isPrevious: false,
        sortOrder: value,
      })

      toggleLoading?.(false)
    }
    set(() => ({ sortValue: value }))
  },
  resetFilteringEvent: () => set(() => ({ filteringEvent: undefined })),
})
