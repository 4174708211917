import { Box } from '@moonpig/launchpad-components'
import React, { FC, useCallback, useMemo, useRef } from 'react'
import { Region } from '@moonpig/web-core-types'
import {
  AddedToBasketEvent,
  ProductClickedEvent,
  ProductGrid,
  ProductTileProduct,
  PromoTileData,
} from '@moonpig/web-shared-products'
import Head from 'next/head'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { GALLERY_PAGE_SIZE } from '../../constants'
import { LoadMoreType } from '../types'
import { InfiniteScroll } from './InfiniteScroll'
import { useLoadPage } from './useLoadPage'
import { setOffsetAndProductIdInUrl } from './setOffsetAndProductIdInUrl'
import { usePagingOffset } from './usePagingOffset'
import { useSearchStore } from '../../store/SearchStore'

const setProductPosition = (product: GalleryProduct) => {
  const productIndex = product.productIndex || 0
  const productPageNumber = Math.floor(productIndex / GALLERY_PAGE_SIZE)
  const productPageOffset = productPageNumber * GALLERY_PAGE_SIZE
  setOffsetAndProductIdInUrl(productPageOffset, product.id)
}

export type GalleryProduct = ProductTileProduct

type ProductGridWithPagingProps = {
  totalCount: number
  products: GalleryProduct[]
  loadMoreProducts: (loadMoreType: LoadMoreType) => Promise<boolean>
  onProductClicked: (clickedEvent: ProductClickedEvent) => void
  onProductFirstClick: (clickedEvent: ProductClickedEvent) => void
  onAddToBasket: (addToBasketEvent: AddedToBasketEvent) => void
  region: Region
  isInternalUser?: boolean
  metaTitle: string
  pageType: string
  groupCardProject?: string
  promoTile?: PromoTileData
}

export const ProductGridWithPaging: FC<ProductGridWithPagingProps> = ({
  totalCount,
  products,
  loadMoreProducts,
  onProductClicked,
  onProductFirstClick,
  onAddToBasket,
  region,
  isInternalUser = false,
  metaTitle,
  pageType,
  groupCardProject,
  promoTile,
}) => {
  const { departments } = useSearchStore(store => store.searchContext)
  const trackingData = useMemo(() => {
    return {
      pageType,
      metaTitle,
      region,
    }
  }, [pageType, metaTitle, region])

  const { loadCallback, loadState, loaded } = useLoadPage({
    loadMoreProducts,
  })

  const shouldInfiniteScrollUp = useRef(false)
  const shouldInfiniteScrollDown = useRef(false)

  const setShouldInfiniteScrollUp = (shouldInfiniteScroll: boolean) => {
    shouldInfiniteScrollUp.current = shouldInfiniteScroll
  }

  const setShouldInfiniteScrollDown = (shouldInfiniteScroll: boolean) => {
    shouldInfiniteScrollDown.current = shouldInfiniteScroll
  }

  const { offsetBorderApproaching, currentPageNumber } = usePagingOffset({
    loaded,
  })

  const endOfPageApproaching = useCallback(() => {
    if (shouldInfiniteScrollDown.current) {
      loadCallback(LoadMoreType.SCROLL)
    }
  }, [loadCallback, shouldInfiniteScrollDown])

  const topOfPageApproaching = useCallback(() => {
    if (shouldInfiniteScrollUp.current) {
      loadCallback(LoadMoreType.SCROLL_PREV)
    }
  }, [loadCallback, shouldInfiniteScrollUp])

  const handleProductClick = useCallback(
    (event: ProductClickedEvent) => {
      const { product } = event
      setProductPosition(product)
      onProductClicked(event)
    },
    [onProductClicked],
  )
  const dynamicTitle = `${metaTitle} - Page ${currentPageNumber} | ${BRAND_LABEL}`

  return (
    <>
      {currentPageNumber > 1 && (
        <Head>
          <title key="page-dynamic-title">{dynamicTitle}</title>
          <meta name="title" content={dynamicTitle} key="dynamic-meta-title" />
        </Head>
      )}
      <Box>
        <InfiniteScroll
          loaded={loaded}
          products={products}
          totalCount={totalCount}
          loadCallback={loadCallback}
          loadState={loadState}
          setShouldInfiniteScrollUp={setShouldInfiniteScrollUp}
          setShouldInfiniteScrollDown={setShouldInfiniteScrollDown}
        >
          <ProductGrid
            products={products}
            totalNumberOfProducts={totalCount}
            onProductFirstClick={onProductFirstClick}
            onProductClicked={handleProductClick}
            onAddToBasket={onAddToBasket}
            onEndApproaching={endOfPageApproaching}
            onTopApproaching={topOfPageApproaching}
            onOffsetBorderApproaching={offsetBorderApproaching}
            isInternalUser={isInternalUser}
            departments={departments}
            trackingData={trackingData}
            groupCardProject={groupCardProject}
            promoTile={promoTile}
          />
        </InfiniteScroll>
      </Box>
    </>
  )
}
