import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { FilterItem } from '../../../services/types/services'
import {
  FilterHeader,
  FilterCategory,
  FilterOption,
  FilterToggle,
} from '../../../components/FiltersMenu/components'

type Props = {
  filterItem: FilterItem
  onClick: (filter: FilterItem) => void
  level?: number
  indentation?: number
}

const StyledFilterToggleItem = styled.div`
  ${s({
    borderBottom: 1,
    borderColor: 'colorBorder04',
  })}

  &:hover {
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBlack05',
    })}
  }

  &:active {
    ${s({
      bgcolor: 'colorBackground03',
      borderColor: 'colorBlack10',
    })}
  }

  &:focus,
  &:focus-visible {
    outline-color: ${colorValue('colorInteractionFocusIndicator')};
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBlack05',
      color: 'colorInteractionFocusIndicator',
    })}
  }
`

export const mapFilterItem = ({
  filterItem,
  onClick,
  level = 0,
  indentation,
}: Props) => {
  switch (filterItem.__typename) {
    case 'FilterHeader':
      return (
        <FilterHeader
          label={filterItem.label}
          key={`${filterItem.label}-header`}
        />
      )

    case 'FilterCategory':
      return filterItem.children.length ? (
        <FilterCategory
          filter={filterItem}
          onClick={onClick}
          key={`${filterItem.id}-category`}
          level={level}
        />
      ) : null

    case 'Filter':
      return (
        <FilterOption
          filter={filterItem}
          indentation={indentation}
          key={`${filterItem.id}-filter`}
          onClick={onClick}
        />
      )

    case 'FilterToggle':
    case 'FilterInvertedToggle':
      return (
        <StyledFilterToggleItem key={`${filterItem.id}-filter`}>
          <FilterToggle filter={filterItem} onChange={onClick} />
        </StyledFilterToggleItem>
      )

    /* istanbul ignore next */
    default:
      return null
  }
}
