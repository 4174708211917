import { ApplicableFilter, FilterItem } from '../../services/types/services'

export const optimisticUpdatedFilterMapping = (
  f: FilterItem,
  filter: ApplicableFilter,
  select: boolean,
): FilterItem => {
  if (f.__typename === 'FilterCategory') {
    return {
      ...f,
      children: f.children.map(c => {
        if (c.__typename === 'Filter' && c.id === filter.id) {
          return {
            ...c,
            isSelected: select,
          }
        }

        if (c.__typename === 'FilterCategory' && c.id === filter.id) {
          return {
            ...c,
            children: c.children.map(fc => {
              return {
                ...fc,
                isSelected: select,
              }
            }),
            isSelected: select,
          }
        }

        if (c.__typename === 'FilterCategory') {
          return optimisticUpdatedFilterMapping(c, filter, select)
        }
        return c
      }),
    }
  }

  if (f.__typename === 'FilterToggle' && f.id === filter.id) {
    return { ...f, isSelected: select }
  }
  return f
}
