import React from 'react'
import { LocaleTextHook } from '@moonpig/web-core-locale-text'
import { MAX_APPLIED_FILTERS_TO_SHOW_NBAS } from './constants'
import { ApplicableFilter, Filter } from '../../services/types/services'
import { FilterChip, CarouselItem, FilterChipVariant } from './components'
import { FIND_TEXT } from '../../text-localisation/localeText'

type Props = {
  filters: Filter[]
  selectedFilters: ApplicableFilter[]
  resultsLoading: boolean
  optimisticRender: boolean
  localiseText: LocaleTextHook<typeof FIND_TEXT>
  useDropdownFilters: boolean
}

export const createCarouselFilterItems = ({
  filters,
  selectedFilters,
  resultsLoading,
  optimisticRender,
  localiseText,
  useDropdownFilters,
}: Props) => {
  const appliedFilters = selectedFilters.map(filter => {
    const filterWithLabel =
      filter.__typename === 'FilterInvertedToggle'
        ? {
            ...filter,
            label: localiseText('find.inverted_toggle_filter_label', {
              label: filter.label,
            }),
          }
        : filter

    const key = `${filter.id}-applied`

    return useDropdownFilters ? (
      <FilterChipVariant key={key} filter={filterWithLabel} select={false} />
    ) : (
      <FilterChip key={key} filter={filterWithLabel} select={false} />
    )
  })

  const getNextBestActionFilters = () => {
    if (
      resultsLoading ||
      optimisticRender ||
      selectedFilters.length >= MAX_APPLIED_FILTERS_TO_SHOW_NBAS
    ) {
      return [
        <CarouselItem key={'empty-nbas'}>
          <></>
        </CarouselItem>,
      ]
    }

    return filters
      .map(filter => ({
        ...filter,
        nbaScore: filter.nbaScore ?? /* istanbul ignore next */ 0,
      }))
      .filter(filter => filter.nbaScore)
      .sort((a, b) => a.nbaScore - b.nbaScore)
      .map(filter => {
        const key = `${filter.id}-available-nbas`
        return useDropdownFilters ? (
          <FilterChipVariant key={key} filter={filter} select />
        ) : (
          <FilterChip key={key} filter={filter} select />
        )
      })
  }

  const nextBestActionFilters = getNextBestActionFilters()

  return [...appliedFilters, ...nextBestActionFilters]
}
