import {
  DepartmentsEnum,
  SponsoredProductInput,
  SortOrder,
} from '@moonpig/web-explore-types-graphql'
import { useReducer } from 'react'
import { useFlag } from '@moonpig/web-explore-flags'
import { SearchProduct, SearchService } from '../../services'
import {
  DepartmentCount,
  SearchFilter,
  SearchRequestResponse,
  SearchSortOrder,
} from '../../services/types/services'
import { Action, reducer } from './state'
import { useFiltersWithSuggestion } from './useFiltersWithSuggestion'
import { useWatchParameters } from './useWatchParameters'
import { GALLERY_PAGE_SIZE } from '../../constants'
import { SelectedSuggestion } from '../../components/types'
import { useSearchStore } from '../../store/SearchStore'
import { removeMoonpigPlusDiscountPill } from './removeMoonpigPlusDiscountPill'

type LoadMoreCallback = (isPrevious?: boolean) => Promise<boolean>

type LoadedCallbackProps = {
  products: SearchProduct[]
  suggestions: SearchProduct[]
  departmentCounts: DepartmentCount[]
  supportedSortOrders: SearchSortOrder[]
  totalCount: number
}

export type UseSearchServicesProps = {
  services: SearchService
  initialResponse: SearchRequestResponse
  parameters: {
    searchTerm: string
    filters: SearchFilter[]
    department: DepartmentsEnum[]
    sort: SortOrder
    promotionId?: string
    selectedSuggestion?: SelectedSuggestion
    sponsoredProducts: SponsoredProductInput[]
  }
}

export type LoadedState = {
  loadedMoreProducts: boolean
  lastLoadedPage: number
}

export type UseProductsReturn = {
  departmentCounts: DepartmentCount[]
  products: SearchProduct[]
  suggestions: SearchProduct[]
  supportedSortOrders: SearchSortOrder[]
  count: number
  loading: boolean
  loadMore: LoadMoreCallback
  loadedState: LoadedState
  totalClickRankDocumentCount: number
}

const initialState = {
  loading: false,
  paging: false,
  loadedMoreProducts: false,
}

export const useSearch: (
  props: UseSearchServicesProps,
) => UseProductsReturn = ({
  services,
  initialResponse: {
    page: initialPage,
    products: initialProducts,
    suggestions: initialSuggestions,
    totalCount: initialCount,
    departments: initialDepartmentCounts,
    supportedSortOrders: initialSupportedSortOrders,
    totalClickRankDocumentCount: initialTotalClickRankDocumentCount,
  },
  parameters: {
    searchTerm,
    department,
    sort,
    filters,
    selectedSuggestion,
    promotionId,
    sponsoredProducts,
  },
}) => {
  const shouldUseSearchStore = useFlag('search-state-management-refactor')
  const updateResults = useSearchStore(store => store.updateResults)
  const [
    {
      products,
      suggestions,
      count,
      departmentCounts,
      loading,
      paging,
      topPage,
      bottomPage,
      lastLoadedPage,
      loadedMoreProducts,
      supportedSortOrders,
      totalClickRankDocumentCount,
    },
    dispatch,
  ] = useReducer(reducer, {
    ...initialState,
    products: initialProducts,
    suggestions: initialSuggestions,
    topPage: initialPage,
    bottomPage: initialPage,
    lastLoadedPage: initialPage,
    departmentCounts: initialDepartmentCounts,
    supportedSortOrders: initialSupportedSortOrders,
    count: initialCount,
    totalClickRankDocumentCount: initialTotalClickRankDocumentCount,
  })

  const filtersWithSuggestion = useFiltersWithSuggestion({
    filters,
    selectedSuggestion,
  })

  const loadProducts = async ({
    loadingAction,
    newPage,
    onProductData,
  }: {
    loadingAction: Action
    newPage: number
    onProductData: (props: LoadedCallbackProps) => Action
  }) => {
    if (!shouldUseSearchStore) {
      dispatch(loadingAction)

      const { departments: newDepartmentCounts, ...result } =
        await services.search({
          searchTerm,
          departments: department,
          sortOrder: sort,
          filters: filtersWithSuggestion,
          page: newPage,
          promotionId,
          sponsoredProducts,
        })

      dispatch(
        onProductData({
          ...result,
          departmentCounts: newDepartmentCounts,
        }),
      )
      updateResults({ departments: newDepartmentCounts, ...result })
    }
  }

  const reloadProducts = async () => {
    await loadProducts({
      loadingAction: { type: 'PRODUCTS_RELOADING' },
      newPage: 1,
      onProductData: x => ({
        type: 'PRODUCTS_RELOADED',
        products: x.products,
        suggestions: x.suggestions,
        departmentCounts: x.departmentCounts,
        totalCount: x.totalCount,
        supportedSortOrders: x.supportedSortOrders,
      }),
    })
  }

  useWatchParameters({
    searchTerm,
    department: department[0],
    filters: filtersWithSuggestion,
    sort,
    loading,
    promotionId,
    callback: reloadProducts,
  })

  const loadMore: LoadMoreCallback = async isPrevious => {
    /* istanbul ignore next */
    if (paging || loading) {
      return true
    }

    /* istanbul ignore next */
    if (products.length >= count || count <= GALLERY_PAGE_SIZE) {
      return false
    }

    const newPage = isPrevious ? topPage - 1 : bottomPage + 1
    if (!shouldUseSearchStore) {
      await loadProducts({
        loadingAction: { type: 'PRODUCTS_PAGING' },
        newPage,
        onProductData: x => ({
          type: 'PRODUCTS_PAGE_LOADED',
          page: newPage,
          products: x.products,
          departmentCounts: x.departmentCounts,
          isPrevious: isPrevious || false,
        }),
      })
    }
    return true
  }

  return {
    departmentCounts,
    supportedSortOrders,
    products: useFlag('disable-moonpig-plus-pills')
      ? removeMoonpigPlusDiscountPill(products)
      : products,
    suggestions,
    lastLoadedPage,
    loading,
    count,
    loadMore,
    loadedState: {
      loadedMoreProducts,
      lastLoadedPage,
    },
    totalClickRankDocumentCount,
  }
}
