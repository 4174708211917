import React, { FC } from 'react'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { RecentlyViewedProducts as RecentlyViewedProductsControl } from './RecentlyViewedProducts'
import { RecentlyViewedProducts as RecentlyViewedProductsVariant } from './RecentlyViewedProductsVariant'

type RecentlyViewedProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  pageTitle: string
}

export const RecentlyViewed: FC<RecentlyViewedProps> = ({
  isOpen,
  onClose,
  onOpen,
  pageTitle,
}) => {
  const useDropdownFilters =
    useExperimentString('search-use-dropdown-filters-v2', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  return useDropdownFilters ? (
    <RecentlyViewedProductsVariant pageTitle={pageTitle} />
  ) : (
    <RecentlyViewedProductsControl
      pageTitle={pageTitle}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    />
  )
}
