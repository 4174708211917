import { useContext, useEffect, useState } from 'react'
import { useFlag } from '@moonpig/web-explore-flags'
import { productListingPageContext } from '../../utils/productListingPageContext'
import { GALLERY_PAGE_SIZE } from '../../constants'
import { setOffsetAndProductIdInUrl } from './setOffsetAndProductIdInUrl'
import { useSearchStore } from '../../store/SearchStore'

const setPageOffset = setOffsetAndProductIdInUrl as (offset: number) => void

export const usePagingOffset = ({ loaded }: { loaded: boolean }) => {
  const shouldUseSearchStore = useFlag('search-state-management-refactor')
  const [{ initialOffset }] = useContext(productListingPageContext)
  const initialOffsetFromstore = useSearchStore(state => state.initialOffset)
  const offset = shouldUseSearchStore ? initialOffsetFromstore : initialOffset
  const [currentPageNumber, setCurrentPageNumber] = useState(
    offset / GALLERY_PAGE_SIZE + 1,
  )

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [offset])

  const offsetBorderApproaching = (productIndex: number) => {
    /* istanbul ignore next */
    if (loaded) {
      setPageOffset(productIndex)
      setCurrentPageNumber(productIndex / GALLERY_PAGE_SIZE + 1)
    }
  }

  return { currentPageNumber, offsetBorderApproaching }
}
