import { TilesPerRow } from '../../types'
import { ProductTileProduct } from '../ProductTile'
import { AD_CELL_WIDTH, AD_CELL_WIDTH_WIDE } from '../PromoTile/constants'
import { GalleryProduct, ProductGridComponent, ProductGridItem } from './types'

const promoTile = {
  component: ProductGridComponent.promoTile,
  id: ProductGridComponent.promoTile,
}

const csatTile = {
  component: ProductGridComponent.csatTile,
  id: ProductGridComponent.csatTile,
}

export const formatGridLayout = ({
  products,
  promoTileLocation,
  csatTileLocation,
}: {
  products: ProductTileProduct[]
  promoTileLocation: number
  csatTileLocation: number | undefined
}) => {
  if (!products || products.length === 0) return []

  if (!promoTileLocation && !csatTileLocation) return products

  return products.reduce(
    (
      acc: ProductGridItem[],
      product: ProductTileProduct | ProductGridItem,
      currentIndex: number,
    ) => {
      const items = [...acc]

      if (promoTileLocation && currentIndex === promoTileLocation) {
        items.push(promoTile)
      }

      if (csatTileLocation && currentIndex === csatTileLocation) {
        items.push(csatTile)
      }

      items.push({ ...product })

      if (
        csatTileLocation === products.length &&
        currentIndex === products.length - 1
      ) {
        items.push(csatTile)
      }

      return items
    },
    [],
  )
}

export const calculateComponentWidth = (
  component: string,
  numOfTilesPerRow: number,
) => {
  switch (component) {
    case ProductGridComponent.promoTile:
      if (numOfTilesPerRow === AD_CELL_WIDTH_WIDE) return '100%'
      return AD_CELL_WIDTH / numOfTilesPerRow
    case ProductGridComponent.csatTile:
    case ProductGridComponent.giftXSellCarousel:
      return '100%'
    default:
      return 1 / numOfTilesPerRow
  }
}

export const calculateCsatPosition = ({
  currentTilesPerRow,
  tilesPerRow,
  desktopRowPosition,
  mobileRowPosition,
  products,
}: {
  currentTilesPerRow: number
  tilesPerRow: TilesPerRow
  desktopRowPosition: number
  mobileRowPosition: number
  products: GalleryProduct[]
}) => {
  const isMobile = currentTilesPerRow <= tilesPerRow.sm
  const rowPosition = isMobile ? mobileRowPosition : desktopRowPosition
  const rows = Math.floor(products.length / currentTilesPerRow)

  if (
    (isMobile && rows < mobileRowPosition) ||
    (!isMobile && rows < desktopRowPosition)
  ) {
    return products.length
  }
  return rowPosition * currentTilesPerRow
}
