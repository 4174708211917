import { isLocalStorageAvailable } from '@moonpig/web-core-utils'
import { DateTime } from 'luxon'

export const trySetLocalStorage = (key: string, value: string) => {
  try {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(key, value)
    }
  } catch {
    /* empty */
  }
}

export const tryGetLocalStorage = (key: string) => {
  try {
    return isLocalStorageAvailable() ? localStorage.getItem(key) : null
  } catch {
    return null
  }
}

export const tryRemoveItemLocalStorage = (key: string) => {
  try {
    return isLocalStorageAvailable() ? localStorage.removeItem(key) : null
  } catch {
    return null
  }
}

export const trySetLocalStorageWithExpiry = (key: string, value: string) => {
  try {
    trySetLocalStorage(`${key}_exp`, DateTime.now().plus({ days: 3 }).toISO())
    trySetLocalStorage(key, value)
  } catch {
    /* empty */
  }
}

const getExpiryDateByKey = (key: string) => {
  const expiryDateItem = localStorage.getItem(`${key}_exp`)
  return expiryDateItem ? DateTime.fromISO(expiryDateItem) : null
}

const isNotExpired = (expiryDate: DateTime) => {
  const currentDate = DateTime.now()
  return DateTime.min(currentDate, expiryDate).equals(currentDate)
}

const removeLocalStorageItemWithExpiryByKey = (key: string) => {
  localStorage.removeItem(`${key}_exp`)
  localStorage.removeItem(key)
}

export const tryGetLocalStorageWithExpiry = (key: string) => {
  try {
    if (isLocalStorageAvailable()) {
      const expiryDate = getExpiryDateByKey(key)

      if (expiryDate && isNotExpired(expiryDate)) {
        return localStorage.getItem(key)
      }

      removeLocalStorageItemWithExpiryByKey(key)

      return null
    }
    throw new Error('Local storage is not available')
  } catch {
    return null
  }
}
