import React, { FC } from 'react'
import {
  Box,
  Flex,
  Heading,
  ScreenReaderOnly,
  Text,
} from '@moonpig/launchpad-components'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { useFlag } from '@moonpig/web-explore-flags'
import { useProductListingPageContext } from '../../utils/productListingPageContext'
import { useFindLocaleText } from '../../text-localisation'
import { useSearchStore } from '../../store/SearchStore'

const BREAKPOINT = 'md'

const StyledGalleryHeader = styled(Flex)`
  flex-direction: column;
  line-height: 1;

  ${breakpoint(BREAKPOINT)} {
    align-items: center;
    flex-direction: row;
    line-height: 0.8;
  }
`

type Props = {
  totalCount: number
  title: string
}

export const Header: FC<Props> = ({ title, totalCount }) => {
  const shouldUseSearchStore = useFlag('search-state-management-refactor')
  const [state] = useProductListingPageContext()
  const storeLoadingFlag = useSearchStore(store => store.loading)
  const loadingProducts = shouldUseSearchStore
    ? storeLoadingFlag
    : state.loading
  const t = useFindLocaleText()

  return (
    <StyledGalleryHeader>
      {
        <Box mr={3} mb={6}>
          <Heading
            display="inline"
            level="h1"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {title}
          </Heading>
          {!loadingProducts && (
            <Text
              data-testid="web-find-total-product-count"
              typography={{
                xs: 'typeMobileDisplay04',
                md: 'typeDesktopDisplay05',
              }}
              color="colorTextBody"
            >
              &nbsp;{`(${totalCount})`}
              <ScreenReaderOnly>
                {t('find.gallery_title_results_found')}
              </ScreenReaderOnly>
            </Text>
          )}
        </Box>
      }
    </StyledGalleryHeader>
  )
}
