import { DepartmentsEnum, SortOrder } from '@moonpig/web-explore-types-graphql'
import { ViewGalleryResultsEvent } from '@moonpig/web-core-analytics'
import { SearchFilter } from '../services'
import { getAllParentDepartments } from '../utils/getAllParentDepartments'
import { FilteringEvent } from '../types'
import { SearchSortByOptions } from './types'

type CreateViewGalleryResultsGAEventProps = {
  department: DepartmentsEnum[]
  filters: SearchFilter[]
  filteringEvent?: FilteringEvent
  numberOfResults: number
  pageTitle: string
  sort: SortOrder
}

const maxCharLimit = 500

export const createViewGalleryResultsGAEvent = ({
  department,
  filters,
  filteringEvent,
  numberOfResults,
  pageTitle,
  sort,
}: CreateViewGalleryResultsGAEventProps): ViewGalleryResultsEvent => {
  const source = filteringEvent?.source || 'gallery'
  const selectedFilter = filteringEvent?.filter
  const parentDepartments = getAllParentDepartments(department).join(', ')

  return {
    event: 'view_gallery_results',
    event_data: {
      category: 'gallery',
      action: 'view gallery results',
      label: `${parentDepartments} | ${pageTitle}`.toLowerCase(),
      non_interaction: true,
      value: undefined,
    },

    results_data: {
      corrected_search_term: undefined,
      input_type: source,
      number_of_results: numberOfResults,
      product_category: parentDepartments.toLowerCase(),
      results_list: pageTitle.toLowerCase(),
      sort_by: sort.toLowerCase() as SearchSortByOptions,
    },

    filter_data: {
      filter_name:
        filters.length > 0
          ? filters
              .map(f => f.key)
              .join('|')
              .toLowerCase()
              .substring(0, maxCharLimit)
          : undefined, // maxCharLimit hard limit
      last_filter_interaction: filteringEvent?.action
        ? `${filteringEvent.action} | ${selectedFilter?.parent} | ${selectedFilter?.id}`.toLowerCase()
        : undefined,
      number_of_active_filters: filters.length,
    },
  }
}
