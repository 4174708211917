import { Filter, FilterCategory } from '../services/types/services'
import { createProductsSlice } from './slices/products'
import { createContextSlice } from './slices/context'
import { createFiltersSlice } from './slices/filters'
import { createSharedSlice } from './slices/shared'
import { QueryProductsProps } from './slices/products/types'

export type SearchStoreState = ReturnType<typeof createContextSlice> &
  ReturnType<typeof createFiltersSlice> &
  ReturnType<typeof createProductsSlice> &
  ReturnType<typeof createSharedSlice>

export enum TrackingActionsEnum {
  toggleFiltersMenu = 'toggleFiltersMenu',
  toggleFiltersCategory = 'toggleFiltersCategory',
  clearFilters = 'clearFilters',
  sortBy = 'sortBy',
  queryProducts = 'queryProducts',
  loadMoreProducts = 'loadMoreProducts',
  loadInitialData = 'loadInitialData',
}

export type TrackingActionTypes = keyof typeof TrackingActionsEnum

export type TrackingPayload =
  | Filter[]
  | string[]
  | { category: FilterCategory }[]
  | QueryProductsProps[]
  | boolean[]
