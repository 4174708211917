import React, { FC, useCallback, useEffect, useState } from 'react'
import { IconSystemChevronRight } from '@moonpig/launchpad-assets'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Link, Box, Text } from '@moonpig/launchpad-components'
import { trackGAEvent, useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { spacingPx } from '@moonpig/launchpad-theme'
import {
  createProductItemGAEvent,
  createViewItemListGAEvent,
  ProductItemEventEnum,
} from '@moonpig/web-shared-products'
import { Carousel } from '@moonpig/web-shared-components'
import { ExpandOrMinimiseRecentlyViewedGAEvent } from '../../analytics/GAEvents'
import { useFindLocaleText } from '../../text-localisation'
import { useRecentlyViewedProducts } from '../../utils/useRecentlyViewedProducts'
import { firstToCapitalOtherToLower } from './firstToCapitalOtherToLower'
import { RecentlyViewedProductEnriched } from '../../types'

const SLIDE_UP_FROM_DESKTOP = 'md'
const MOBILE_PRODUCT_IMAGE_SIZE = 50
const DESKTOP_PRODUCT_IMAGE_SIZE = 70
const BUTTON_SIZE_MOBILE = 40
const BUTTON_SIZE = 56
const COLLAPSE_DISTANCE = 20
const TOGGLE_VISIBILITY_DISTANCE = 50
const EXPANDED_HEIGHT = 200
const COLLAPSED_HEIGHT_MOBILE = 48
const COLLAPSED_HEIGHT = 56

const getHeight = (
  isVisible: boolean,
  isExpanded: boolean,
  isMobile: boolean,
) => {
  if (isVisible) {
    if (isExpanded) {
      return EXPANDED_HEIGHT
    }

    return isMobile ? COLLAPSED_HEIGHT_MOBILE : COLLAPSED_HEIGHT
  }

  return 0
}

const StyledSlideUp = styled(Box)<{ isVisible: boolean; isExpanded: boolean }>`
  z-index: 1;
  width: 100%;
  bottom: 0;
  transition: max-height 300ms ease-in-out;
  max-height: ${props => getHeight(props.isVisible, props.isExpanded, true)}px;
  min-height: ${props => getHeight(props.isVisible, props.isExpanded, true)}px;
  pointer-events: auto;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

  ${s({
    bgcolor: 'colorBackground01',
    pl: { xs: 0, md: 8 },
    pr: 0,
  })}

  ${breakpoint(SLIDE_UP_FROM_DESKTOP)} {
    max-height: ${props =>
      getHeight(props.isVisible, props.isExpanded, false)}px;
    min-height: ${props =>
      getHeight(props.isVisible, props.isExpanded, false)}px;
  }
`
const StyledFixedHeader = styled.button`
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${s({
    minHeight: {
      xs: `${COLLAPSED_HEIGHT_MOBILE}px`,
      md: `${COLLAPSED_HEIGHT}px`,
    },
    pl: { xs: 6, md: 3 },
  })}

  ${breakpoint(SLIDE_UP_FROM_DESKTOP)} {
    ${s({
      mb: 2,
    })}
  }
`
const StyledIconChevron = styled(IconSystemChevronRight)`
  justify-content: flex-end;
  transform: rotate(-90deg);
  ${s({
    position: 'relative',
    color: 'colorTextBody',
    width: `${BUTTON_SIZE_MOBILE}px`,
    height: `${BUTTON_SIZE_MOBILE}px`,
    p: 4,
  })}
  &.down {
    transform: rotate(90deg);
  }

  ${breakpoint(SLIDE_UP_FROM_DESKTOP)} {
    ${s({
      width: `${BUTTON_SIZE}px`,
      height: `${BUTTON_SIZE}px`,
      p: 6,
    })}
  }
`
const StyledProductElement = styled(Link)`
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
`
const StyledImage = styled.img`
  width: ${MOBILE_PRODUCT_IMAGE_SIZE}px;
  transform: translateY(0);
  transition: all 200ms ease-in-out;

  ${s({
    boxShadow: 1,
    m: 3,
  })}

  ${breakpoint(SLIDE_UP_FROM_DESKTOP)} {
    width: ${DESKTOP_PRODUCT_IMAGE_SIZE}px;

    &:hover {
      ${s({ boxShadow: 2 })}
      transform: translateY(-${spacingPx(3)});
    }

    ${s({
      my: 3,
      mx: 4,
    })}
  }
`

const StyledCarouselWrapper = styled(Box)`
  ${s({
    pb: 6,
  })}
`

type RecentlyViewedProductsProps = {
  pageTitle: string
}

export const RecentlyViewedProducts: FC<RecentlyViewedProductsProps> = ({
  pageTitle,
}) => {
  const recentlyViewedProducts = useRecentlyViewedProducts()
  const [previousScroll, setPreviousScroll] = useState(0)
  const [scrollDirection, setScrollDirection] = useState(0)
  const [scrollChangePoint, setScrollChangePoint] = useState(0)
  const [isVisible, setIsVisible] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const localiseText = useFindLocaleText()
  const recentlyViewedText = firstToCapitalOtherToLower(
    localiseText('find.recently_viewed'),
  )
  const { trackGAEventOnce } = useTrackGAEventOnce()

  const trackSelectItem = useCallback(
    (
      product: RecentlyViewedProductEnriched,
      itemCount: number,
      index: number,
    ) => {
      const listName = `${pageTitle} | ${recentlyViewedText} | carousel | 1/1`
      const label = `${listName} | ${index + 1}/${itemCount} | ${product.id}`

      trackGAEvent(
        createProductItemGAEvent({
          eventType: ProductItemEventEnum.SELECT_ITEM,
          product,
          index,
          label,
          listName,
          variant: product.masterVariant.title,
        }),
      )
    },
    [pageTitle, recentlyViewedText],
  )

  const handleClick = useCallback(() => {
    const currentlyExpanded = !isExpanded
    setIsExpanded(currentlyExpanded)
    setScrollChangePoint(window.scrollY)

    if (currentlyExpanded) {
      trackGAEventOnce(
        createViewItemListGAEvent({
          products: recentlyViewedProducts,
          label: `${pageTitle} | ${recentlyViewedText} | carousel`,
        }),
      )
    }

    trackGAEvent(
      ExpandOrMinimiseRecentlyViewedGAEvent(
        currentlyExpanded ? 'expand' : 'minimise',
      ),
    )
  }, [
    isExpanded,
    pageTitle,
    recentlyViewedProducts,
    recentlyViewedText,
    trackGAEventOnce,
  ])

  const handleScroll = useCallback(() => {
    const currentScroll = window.scrollY
    const scrolledUp = currentScroll < previousScroll
    const scrolledDown = currentScroll > previousScroll
    let distance = Math.abs(currentScroll - scrollChangePoint)

    if (scrolledDown) {
      if (scrollDirection !== 1) {
        setScrollDirection(1)
        setScrollChangePoint(previousScroll)
        distance = Math.abs(currentScroll - previousScroll)
      }

      if (isVisible && distance > TOGGLE_VISIBILITY_DISTANCE) {
        setIsVisible(false)
      }
    }

    if (scrolledUp) {
      if (scrollDirection !== -1) {
        setScrollDirection(-1)
        setScrollChangePoint(previousScroll)
        distance = Math.abs(currentScroll - previousScroll)
      }

      if (!isVisible && distance > TOGGLE_VISIBILITY_DISTANCE) {
        setIsVisible(true)
      }
    }

    if (isExpanded && distance > COLLAPSE_DISTANCE) {
      setIsExpanded(false)
    }

    setPreviousScroll(currentScroll)
  }, [
    isExpanded,
    previousScroll,
    scrollChangePoint,
    scrollDirection,
    isVisible,
  ])

  useEffect(() => {
    const removeScrollListener = () => {
      window.removeEventListener('scroll', handleScroll)
    }

    window.addEventListener('scroll', handleScroll)

    return removeScrollListener
  }, [handleScroll])

  return (
    <>
      {recentlyViewedProducts.length > 0 && (
        <StyledSlideUp
          isVisible={isVisible}
          isExpanded={isExpanded}
          data-testid="web-find-recently-viewed-variant"
        >
          <StyledFixedHeader
            onClick={handleClick}
            aria-label={
              isExpanded
                ? localiseText('find.close_recently_viewed_products')
                : localiseText('find.open_recently_viewed_products')
            }
            role="button"
            tabIndex={0}
          >
            <Box>
              <Text typography="typeDisplay06" color="colorTextBody">
                {recentlyViewedText}
              </Text>
              <Text
                typography="typeDisplay06"
                fontWeight={400}
                ml={3}
                color="colorBlack40"
              >{`(${recentlyViewedProducts.length})`}</Text>
            </Box>
            <StyledIconChevron className={isExpanded ? 'down' : ''} />
          </StyledFixedHeader>
          <StyledCarouselWrapper>
            <Carousel itemsGap={{ xs: 0 }}>
              {recentlyViewedProducts.map(
                (recentlyViewedProduct, index: number) => (
                  <StyledProductElement
                    href={recentlyViewedProduct.url}
                    key={`recentlyViewed-${recentlyViewedProduct.title}`}
                    onClick={() => {
                      trackSelectItem(
                        recentlyViewedProduct,
                        recentlyViewedProducts.length,
                        index,
                      )
                    }}
                  >
                    <StyledImage
                      src={`${recentlyViewedProduct.image}?w=80`}
                      alt={recentlyViewedProduct.title}
                    />
                  </StyledProductElement>
                ),
              )}
            </Carousel>
          </StyledCarouselWrapper>
        </StyledSlideUp>
      )}
    </>
  )
}
