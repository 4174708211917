import { createViewItemListGAEvent } from '@moonpig/web-shared-products'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import {
  GALLERY_PAGE_SIZE,
  GALLERY_PAGE_TYPE,
  SEARCH_PAGE_TYPE,
} from '../../../constants'
import { FilteringEvent, FilterSource } from '../../../types'
import { SearchFilter, SearchRequestResponse } from '../../../services'
import { ApplicableFilter } from '../../../services/types/services'
import { PageContext, SearchContext } from '../../slices/shared'

type Props = {
  results: SearchRequestResponse
  searchContext: SearchContext
  preAppliedFilters: SearchFilter[]
  selectedFilters: ApplicableFilter[]
  pageContext: PageContext
  lastLoadedPage: number
  filteringEvent?: FilteringEvent
  isPrevious: boolean
}

const getProductFilter = ({
  preAppliedFilters,
  selectedFilters,
  searchContext,
}: {
  preAppliedFilters: SearchFilter[]
  selectedFilters: ApplicableFilter[]
  searchContext: SearchContext
}) => {
  const filtersWithSuggestion = [
    ...preAppliedFilters.map(f => ({
      facetKey: f.key,
      group: f.group,
    })),
    ...selectedFilters.map(f => ({
      facetKey: f.id,
      group: f.parent,
    })),
    ...(searchContext.selectedSuggestion
      ? [
          {
            group: searchContext.selectedSuggestion.group,
            facetKey: searchContext.selectedSuggestion.facetKey,
          },
        ]
      : []),
  ]
  return (
    (filtersWithSuggestion.length &&
      filtersWithSuggestion[
        filtersWithSuggestion.length - 1
      ].facetKey.toLocaleLowerCase()) ||
    /* istanbul ignore next */ 'n/a'
  )
}

const getLoadedProducts = ({
  results,
  isPrevious,
  lastLoadedPage,
}: {
  results: SearchRequestResponse
  isPrevious: boolean
  lastLoadedPage: number
}) => {
  const from = isPrevious
    ? 0
    : lastLoadedPage * GALLERY_PAGE_SIZE - GALLERY_PAGE_SIZE
  const to = isPrevious ? GALLERY_PAGE_SIZE : lastLoadedPage * GALLERY_PAGE_SIZE

  return results.products.slice(from, to)
}

export const trackLoadedProductsEvent = ({
  results,
  searchContext,
  preAppliedFilters,
  selectedFilters,
  pageContext,
  lastLoadedPage,
  filteringEvent,
  isPrevious,
}: Props) => {
  const number_of_results = results.totalCount
  const product_department =
    (searchContext.departments.length &&
      searchContext.departments[0].toLocaleLowerCase()) ||
    /* istanbul ignore next */ 'n/a'
  const product_filter = getProductFilter({
    preAppliedFilters,
    selectedFilters,
    searchContext,
  })
  const product_term = searchContext.searchTerm.toLocaleLowerCase() || 'n/a'
  const page_title = pageContext.title.toLocaleLowerCase()
  const loadedProducts = getLoadedProducts({
    results,
    isPrevious,
    lastLoadedPage,
  })

  if (pageContext.type === GALLERY_PAGE_TYPE) {
    const event = createViewItemListGAEvent({
      products: loadedProducts,
      label: `${pageContext.type} | ${product_department} | ${product_filter} | ${product_term} | ${page_title}`,
      gallery_data: {
        number_of_results,
        product_department,
        product_filter,
        product_term,
        page_title,
      },
    })
    trackGAEvent(event)
  }

  if (pageContext.type === SEARCH_PAGE_TYPE) {
    const filterSource =
      filteringEvent &&
      filteringEvent.source !== FilterSource.Gallery &&
      filteringEvent.source

    const event = createViewItemListGAEvent({
      products: loadedProducts,
      label: `${pageContext.type} | ${product_department} | ${product_term} | ${page_title}`,
      search_data: {
        index: '',
        input_type: filterSource || 'keyword',
        number_of_results,
        product_department,
        search_term: product_term,
        page_title,
      },
    })
    trackGAEvent(event)
  }
}
