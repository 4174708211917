import React, { FC, useCallback } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { FilterToggle } from '../../FiltersMenu/components'
import { FilterSource } from '../../../types'
import { useSearchStore } from '../../../store/SearchStore'
import {
  InvertedFilterToggle,
  FilterToggle as FilterToggleType,
} from '../../../services/types/services'

/* instanbul ignore next*/
const StyledQuickFilterToggle = styled.div<{
  isApplied: boolean
}>`
  ${({ isApplied }) =>
    s({
      py: 0,
      px: 0,
      mx: 3,
      my: { xs: 5, md: 6 },
      borderColor: isApplied ? 'colorInteractionSelectedState' : 'colorBlack10',
      bgcolor: 'colorBackground01',
      color: isApplied ? 'colorInteractionSelectedState' : 'colorTextLabel',
      borderRadius: 2,
      border: 2,
      maxHeight: '48px',
      boxShadow: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    })}

  label {
    ${({ isApplied }) =>
      s({
        color: isApplied ? 'colorInteractionSelectedState' : 'colorTextLabel',
      })}
  }

  &:not(:disabled) {
    &:focus,
    &:focus-visible,
    &:active {
      text-decoration: none;
      outline: none;
      ${({ isApplied }) =>
        s({
          borderColor: isApplied
            ? 'colorInteractionSelectedState'
            : 'colorBlack30',
          bgcolor: isApplied ? 'colorBackground03' : 'colorBorder04',
          boxShadow: 5,
        })}
    }

    &:hover {
      ${({ isApplied }) =>
        s({
          borderColor: isApplied
            ? 'colorInteractionSelectedState'
            : 'colorBlack30',
          bgcolor: isApplied ? 'colorBackground03' : 'colorBorder04',
          boxShadow: 0,
        })}
    }

    &:hover,
    &:focus,
    &:focus-visible label {
      ${({ isApplied }) =>
        s({
          color: isApplied ? 'colorInteractionSelectedState' : 'colorTextLabel',
        })}
    }
  }
`

type Props = {
  toggleFilter: FilterToggleType | InvertedFilterToggle
}

export const QuickFilterToggle: FC<Props> = ({ toggleFilter }) => {
  const toggleQuickFilter = useSearchStore(store => store.toggleFilter)
  const isApplied = !!toggleFilter.isSelected

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
        case ' ': // Space
          event.preventDefault()
          toggleQuickFilter({
            filter: toggleFilter,
            select: !isApplied,
            source: FilterSource.QuickFilters,
          })
          break
      }
    },
    [toggleQuickFilter, toggleFilter, isApplied],
  )

  return (
    <StyledQuickFilterToggle
      onKeyDown={handleKeyDown}
      onClick={e => {
        e.preventDefault()
        toggleQuickFilter({
          filter: toggleFilter,
          select: !isApplied,
          source: FilterSource.QuickFilters,
        })
        e.currentTarget.blur()
      }}
      data-testid={`filter-toggle-${toggleFilter.id}`}
      tabIndex={0}
      isApplied={isApplied}
    >
      <FilterToggle
        filter={toggleFilter}
        showIcon={false}
        isQuickFilter
        onChange={() => {}}
      />
    </StyledQuickFilterToggle>
  )
}
