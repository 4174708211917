/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { DocumentNode } from 'graphql'
import { useLazyQuery as useLazyQueryCore } from '@moonpig/web-core-graphql'
import { MaybeMasked } from '@apollo/client'

type QueryData = any

type QueryVariables = Record<string, any>

type QueryResult<T> = { data?: T; loading?: boolean; called?: boolean }

type LazyQueryFunction = () => void

type LazyQueryResult<T> = [LazyQueryFunction, QueryResult<T>]

export const useLazyQuery = <
  TData = QueryData,
  TVariables extends QueryVariables = QueryVariables,
>(
  query: DocumentNode,
  options?: { variables: TVariables; ssr?: boolean },
): LazyQueryResult<MaybeMasked<TData>> => {
  const [action, { data: currentData, previousData, ...rest }] =
    useLazyQueryCore<TData, TVariables>(query, options)

  const data = useMemo(
    () => currentData ?? previousData,
    [currentData, previousData],
  )

  return [action, { data, ...rest }]
}
