const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z0-9])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  )

export const getExperimentsFromCampaigns = (campaigns: {
  [key: string]: string
}) => {
  const experiments =
    campaigns &&
    Object.entries(campaigns).reduce(
      (acc, [campaignName, variant]) => ({
        ...acc,
        [snakeToCamel(campaignName)]: variant,
      }),
      {},
    )
  return experiments
}
