import {
  ApplicableFilter,
  FilterCategory,
  FilterItem,
} from '../../services/types/services'
import {
  persistFilterSelection,
  isPersistedFilter,
} from './persistFilterSelection'

const addAllChildren = (
  filter: FilterCategory,
  updatedSelectedFilters: ApplicableFilter[],
) => {
  filter.children.forEach(child => {
    if (
      child.__typename !== 'FilterHeader' &&
      !updatedSelectedFilters.some(f => f.id === child.id)
    ) {
      updatedSelectedFilters.push(child)
    }
  })
}

const addFilter = (
  newFilter: ApplicableFilter,
  updatedSelectedFilters: ApplicableFilter[],
) => {
  if (newFilter.__typename === 'FilterCategory') {
    addAllChildren(newFilter, updatedSelectedFilters)
  }

  if (!updatedSelectedFilters.some(filter => filter.id === newFilter.id)) {
    updatedSelectedFilters.push(newFilter)
  }
}

const getFiltersToRemove = (
  newFilter: FilterCategory,
  updatedSelectedFilters: ApplicableFilter[],
) => {
  return updatedSelectedFilters
    .filter(filter =>
      newFilter.children.some(
        child => child.__typename !== 'FilterHeader' && child.id === filter.id,
      ),
    )
    .map(filter => filter.id)
}

const getParentFilterId = (
  childFilter: ApplicableFilter,
  allFilters: FilterItem[],
) => {
  let parentFilterId = ''
  allFilters.forEach((filter: FilterItem) => {
    if (filter.__typename === 'FilterCategory') {
      if (
        filter.children.some(
          child =>
            child.__typename !== 'FilterHeader' && child.id === childFilter.id,
        )
      ) {
        parentFilterId = filter.id
      }
      if (
        filter.children.some(child => child.__typename === 'FilterCategory')
      ) {
        parentFilterId = getParentFilterId(childFilter, filter.children)
      }
    }
  })

  return parentFilterId
}

const removeAllChildren = (
  newFilter: FilterCategory,
  updatedSelectedFilters: ApplicableFilter[],
) => {
  const filtersToRemove = getFiltersToRemove(newFilter, updatedSelectedFilters)

  return updatedSelectedFilters.filter(
    filter => !filtersToRemove.includes(filter.id),
  )
}

const removeFilter = (
  newFilter: ApplicableFilter,
  updatedSelectedFilters: ApplicableFilter[],
  allFilters: FilterItem[],
) => {
  let currentSelectedFilters = [...updatedSelectedFilters]
  if (newFilter.__typename === 'FilterCategory') {
    currentSelectedFilters = removeAllChildren(
      newFilter,
      updatedSelectedFilters,
    )
  }

  return currentSelectedFilters.filter(filter => {
    const parentFilterId = getParentFilterId(newFilter, allFilters)
    return filter.id !== newFilter.id && filter.id !== parentFilterId
  })
}

export const updateSelectedFilters = ({
  allFilters,
  selectedFilters,
  newFilter,
  select,
}: {
  allFilters: FilterItem[]
  selectedFilters: ApplicableFilter[]
  newFilter: ApplicableFilter
  select: boolean
}): ApplicableFilter[] => {
  let updatedSelectedFilters = [...selectedFilters]

  if (select) {
    addFilter(newFilter, updatedSelectedFilters)
  } else {
    updatedSelectedFilters = removeFilter(
      newFilter,
      updatedSelectedFilters,
      allFilters,
    )
  }

  if (isPersistedFilter(newFilter)) {
    persistFilterSelection(newFilter, select)
  }

  return updatedSelectedFilters
}
