import React, { FC, useCallback, useEffect, useState } from 'react'
import { IconSystemChevronRight } from '@moonpig/launchpad-assets'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Link, Box, Text, SimpleCarousel } from '@moonpig/launchpad-components'
import { trackGAEvent, useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { spacingPx } from '@moonpig/launchpad-theme'
import {
  createProductItemGAEvent,
  createViewItemListGAEvent,
  ProductItemEventEnum,
} from '@moonpig/web-shared-products'
import { ExpandOrMinimiseRecentlyViewedGAEvent } from '../../analytics/GAEvents'
import { useFindLocaleText } from '../../text-localisation'
import { useRecentlyViewedProducts } from '../../utils/useRecentlyViewedProducts'
import { firstToCapitalOtherToLower } from './firstToCapitalOtherToLower'
import { RecentlyViewedProductEnriched } from '../../types'

const SLIDE_UP_FROM_DESKTOP = 'md'
const MOBILE_PRODUCT_IMAGE_SIZE = 50
const DESKTOP_PRODUCT_IMAGE_SIZE = 70
const BUTTON_SIZE = 56
export const DATA_TEST_ID = 'web-find-recently-viewed'
export const HEADER_LABEL_OPEN = 'Open recently viewed products'
export const HEADER_LABEL_CLOSE = 'Close recently viewed products'
export const EXPANDED_HEIGHT = '200px'
export const MINIMISED_HEIGHT = '56px'

const StyledSlideUp = styled(Box)<{ isOpen: boolean }>`
  z-index: 1;
  width: 100%;
  bottom: 0;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
  transition: max-height 500ms ease-in-out;
  max-height: ${props => (props.isOpen ? EXPANDED_HEIGHT : MINIMISED_HEIGHT)};
  pointer-events: auto;

  ${s({
    bgcolor: 'colorBackground01',
    px: 6,
    pb: 6,
  })}
`
const StyledFixedHeader = styled.button`
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${breakpoint(SLIDE_UP_FROM_DESKTOP)} {
    ${s({
      mb: 2,
    })}
  }
`
const StyledIconChevron = styled(IconSystemChevronRight)`
  justify-content: flex-end;
  transform: rotate(-90deg);
  ${s({
    position: 'relative',
    color: 'colorTextBody',
    width: `${BUTTON_SIZE}px`,
    height: `${BUTTON_SIZE}px`,
    p: 6,
  })}
  &.down {
    transform: rotate(90deg);
  }
`
const StyledProductElement = styled(Link)`
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
`
const StyledImage = styled.img`
  width: ${MOBILE_PRODUCT_IMAGE_SIZE}px;
  ${s({ boxShadow: 2 })}
  transform: translateY(0);
  transition: all 200ms ease-in-out;

  ${s({
    m: 3,
  })}

  ${breakpoint(SLIDE_UP_FROM_DESKTOP)} {
    width: ${DESKTOP_PRODUCT_IMAGE_SIZE}px;

    &:hover {
      ${s({ boxShadow: 4 })}
      transform: translateY(-${spacingPx(3)});
    }

    ${s({
      my: 3,
      mx: 4,
    })}
  }
`

type RecentlyViewedProductsProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  pageTitle: string
}

export const RecentlyViewedProducts: FC<RecentlyViewedProductsProps> = ({
  isOpen,
  onClose,
  onOpen,
  pageTitle,
}) => {
  const recentlyViewedProducts = useRecentlyViewedProducts()
  const [prevScrollPosition, setPrevScrollPosition] = useState(0)
  const localiseText = useFindLocaleText()
  const recentlyViewedText = firstToCapitalOtherToLower(
    localiseText('find.recently_viewed'),
  )
  const { trackGAEventOnce } = useTrackGAEventOnce()

  useEffect(() => {
    if (recentlyViewedProducts.length && isOpen) {
      trackGAEventOnce(
        createViewItemListGAEvent({
          products: recentlyViewedProducts,
          label: `${pageTitle} | ${recentlyViewedText} | carousel`,
        }),
      )
    }
  }, [
    isOpen,
    pageTitle,
    recentlyViewedProducts,
    recentlyViewedText,
    trackGAEventOnce,
  ])

  const trackSelectItem = useCallback(
    (
      product: RecentlyViewedProductEnriched,
      itemCount: number,
      index: number,
    ) => {
      const listName = `${pageTitle} | ${recentlyViewedText} | carousel | 1/1`
      const label = `${listName} | ${index + 1}/${itemCount} | ${product.id}`

      trackGAEvent(
        createProductItemGAEvent({
          eventType: ProductItemEventEnum.SELECT_ITEM,
          product,
          index,
          label,
          listName,
          variant: product.masterVariant.title,
        }),
      )
    },
    [pageTitle, recentlyViewedText],
  )

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset
    const srolledDistance = Math.abs(currentScrollPos - prevScrollPosition)
    /* istanbul ignore next */
    if (isOpen && srolledDistance > 10 && onClose) {
      onClose()
    }
    setPrevScrollPosition(currentScrollPos)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, prevScrollPosition])

  useEffect(() => {
    const removeScrollListener = () => {
      window.removeEventListener('scroll', handleScroll)
    }

    window.addEventListener('scroll', handleScroll)

    return removeScrollListener
  }, [handleScroll])

  return (
    <>
      {recentlyViewedProducts.length > 0 && (
        <StyledSlideUp isOpen={isOpen} data-testid={DATA_TEST_ID}>
          <StyledFixedHeader
            onClick={() => {
              if (isOpen) {
                onClose()
              } else {
                onOpen()
              }
              trackGAEvent(
                ExpandOrMinimiseRecentlyViewedGAEvent(
                  !isOpen ? 'expand' : 'minimise',
                ),
              )
            }}
            aria-label={
              isOpen
                ? localiseText('find.close_recently_viewed_products')
                : localiseText('find.open_recently_viewed_products')
            }
            role="button"
            tabIndex={0}
          >
            <Box>
              <Text
                typography="typeDisplay05"
                color="colorTextBody"
                id="recently-viewed-heading"
              >
                {recentlyViewedText}
              </Text>
              <Text color="colorBlack40">{` (${recentlyViewedProducts.length})`}</Text>
            </Box>
            <StyledIconChevron className={isOpen ? 'down' : ''} />
          </StyledFixedHeader>
          <SimpleCarousel gap={0} aria-labelledby="recently-viewed-heading">
            {recentlyViewedProducts.map(
              (recentlyViewedProduct, index: number) => (
                <StyledProductElement
                  href={recentlyViewedProduct.url}
                  key={`recentlyViewed-${recentlyViewedProduct.title}`}
                  onClick={() => {
                    trackSelectItem(
                      recentlyViewedProduct,
                      recentlyViewedProducts.length,
                      index,
                    )
                  }}
                >
                  <StyledImage
                    src={`${recentlyViewedProduct.image}?w=80`}
                    alt={recentlyViewedProduct.title}
                  />
                </StyledProductElement>
              ),
            )}
          </SimpleCarousel>
        </StyledSlideUp>
      )}
    </>
  )
}
