import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Text } from '@moonpig/launchpad-components'
import { FilterCategory } from '../../../../services/types/services'
import { useFindLocaleText } from '../../../../text-localisation'
import { getAllSelectedFilters } from '../../../../utils/GalleryFiltering/getAllSelectedFilters'

const StyledSubText = styled(Text)`
  display: block;
  ${s({
    textAlign: 'left',
    color: 'colorInteractionSelectedState',
    typography: 'typeBodyCaption',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    flexGrow: 1,
  })}
`

export const SelectedFiltersList: FC<{
  filter: FilterCategory
}> = ({ filter }) => {
  const localiseText = useFindLocaleText()
  const selectedFilters = getAllSelectedFilters(filter.children)

  if (selectedFilters.length === 0) return null

  if (filter.isSelected) {
    return (
      <StyledSubText>
        {localiseText('find.all', { label: filter.label })}
      </StyledSubText>
    )
  }

  return <StyledSubText>{selectedFilters.join(', ')}</StyledSubText>
}
