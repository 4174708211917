import React, { forwardRef } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { CarouselItem, Chip } from '@moonpig/launchpad-components'
import { ApplicableFilter } from '../../../services/types/services'
import { CAROUSEL_ITEM_HEIGHT_PX } from '../constants'
import { useFindLocaleText } from '../../../text-localisation'
import { FilterSource } from '../../../types'
import { useSearchStore } from '../../../store/SearchStore'

const StyledCarouselItem = styled(CarouselItem)`
  height: ${CAROUSEL_ITEM_HEIGHT_PX};
  overflow: hidden;
`

type FilterChipProps = {
  filter: ApplicableFilter
  select: boolean
}

export const FilterChip = forwardRef<HTMLLIElement, FilterChipProps>(
  ({ filter, select }, ref) => {
    const localiseText = useFindLocaleText()
    const toggleFilter = useSearchStore(store => store.toggleFilter)

    const label = select
      ? localiseText('find.apply_facet_group_filter_facet_label', {
          group: filter.parent,
          label: filter.label.toLowerCase(),
        })
      : localiseText('find.remove_facet_group_filter_facet_label', {
          group: filter.parent,
          label: filter.label,
        })

    return (
      <StyledCarouselItem ref={ref}>
        <Chip
          dismissible
          selected={!select}
          onClick={() =>
            toggleFilter({
              filter,
              select,
              source: FilterSource.NextBestAction,
            })
          }
          aria-label={label}
        >
          {filter.label}
        </Chip>
      </StyledCarouselItem>
    )
  },
)
