import { StateCreator } from 'zustand'
import { SortOrder } from '@moonpig/web-explore-types-graphql'
import { SharedState } from './types'
import { ContextState } from '../context'
import { ProductsState } from '../products'
import { FiltersState } from '../filters'
import { SearchFilter } from '../../../services'
import {
  getPersistedFilters,
  applySelectedFilters,
  removePreAppliedFilters,
  getFiltersById,
} from '../../helpers'

export const createSharedSlice: StateCreator<
  SharedState &
    Partial<ContextState> &
    Partial<ProductsState> &
    Partial<FiltersState>
> = (set, get) => ({
  loading: false,
  shouldUseSearchStoreRefactor: false,
  toggleLoading: (loading = true) => {
    set({
      loading,
    })
  },
  loadInitialState: ({
    pageContext,
    searchContext,
    filters,
    products,
    shouldUseSearchStoreRefactor,
  }) => {
    const { createPageContext, createSearchContext, loadInitialData } = get()

    createPageContext?.(pageContext)
    createSearchContext?.(searchContext)
    loadInitialData({
      productsData: products,
      searchContext,
      filtersData: filters,
      shouldUseSearchStoreRefactor,
    })
  },
  loadInitialData: async ({
    productsData,
    filtersData,
    searchContext,
    shouldUseSearchStoreRefactor,
  }) => {
    const { toggleLoading } = get()
    const { query: queryProducts, initialResponse } = productsData
    const {
      initialFilters,
      query: queryFilters,
      selectedSuggestion,
      urlFilters,
      initialSortValue,
      initialFiltersResponse,
    } = filtersData
    const { searchTerm, departments, promotionId, sponsoredProducts } =
      searchContext

    const querySearch = async ({
      newPage,
      filters,
      sortOrder,
    }: {
      newPage: number
      filters: SearchFilter[]
      sortOrder: SortOrder | undefined
    }) => {
      const searchResponse = await queryProducts({
        searchTerm,
        departments,
        sortOrder,
        filters,
        page: newPage,
        promotionId,
        sponsoredProducts: sponsoredProducts || [],
      })
      return searchResponse
    }

    const preAppliedFilters = selectedSuggestion
      ? [...initialFilters, selectedSuggestion]
      : initialFilters
    const filtersResult =
      shouldUseSearchStoreRefactor && initialFiltersResponse
        ? initialFiltersResponse
        : await queryFilters([...preAppliedFilters, ...urlFilters])
    const persistedFilters = getPersistedFilters()

    toggleLoading?.(false)

    set(() => ({
      filters: applySelectedFilters(
        removePreAppliedFilters(filtersResult.filters, preAppliedFilters),
        [...persistedFilters, ...urlFilters.map(f => f.key)],
      ),
      preAppliedFilters,
      selectedFilters: getFiltersById({
        filterIds: [...urlFilters.map(f => f.key), ...persistedFilters],
        allFilters: filtersResult.filters,
      }),
      queryFilters: async filters => {
        const result = await queryFilters(filters)
        return removePreAppliedFilters(result.filters, preAppliedFilters)
      },
      sortValue: initialSortValue,
      initialSortValue,
      results: initialResponse,
      initialOffset: 0,
      querySearch,
      topPage: initialResponse.page,
      bottomPage: initialResponse.page,
      lastLoadedPage: initialResponse.page,
      shouldUseSearchStoreRefactor,
      filtersMenuOpen: false,
    }))
  },
})
