import { PrimaryButton } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemSort } from '@moonpig/launchpad-assets'
import { useFindLocaleText } from '../../../text-localisation'

const StyledSortButton = styled(PrimaryButton)`
  ${s({
    py: 0,
    my: { xs: 5, md: 6 },
    mr: { xs: 7, lg: 0 },
    ml: 3,
    borderColor: 'colorBorder03',
    bgcolor: 'colorBackground01',
    border: 2,
    color: 'colorInteractionIcon',
    boxShadow: 0,
  })};

  &:not(:disabled) {
    &:hover {
      ${s({
        boxShadow: 0,
        borderColor: 'colorBlack30',
        bgcolor: 'colorBorder04',
        color: 'colorInteractionIcon',
      })}
    }

    &:focus,
    &:focus-visible,
    &:active {
      ${s({
        boxShadow: 5,
        borderColor: 'colorBlack30',
        bgcolor: 'colorBorder04',
        color: 'colorInteractionIcon',
      })}
    }
  }
`

type Props = {
  onClick: () => void
}

export const QuickSortBy: FC<Props> = ({ onClick }) => {
  const localiseText = useFindLocaleText()
  return (
    <StyledSortButton
      trailingIcon={<IconSystemSort aria-hidden width="24px" />}
      onClick={onClick}
      data-testid="quick-sort-by"
    >
      {localiseText('find.sort')}
    </StyledSortButton>
  )
}
