import React, { FC } from 'react'
import { IconSystemCross } from '@moonpig/launchpad-assets'
import { Flex, Heading, IconButton } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { useFindLocaleText } from '../../../../text-localisation'
import { ClearButton } from '../../../ClearButton'
import { FilterSource } from '../../../../types'

const StyledHeader = styled(Flex)`
  ${s({
    position: 'fixed',
    top: 0,
    zIndex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    py: 3,
    pl: 3,
    pr: 6,
    bgcolor: 'colorBackground01',
    borderBottom: 1,
    borderColor: 'colorBorder03',
  })}
`

const StyledTitleText = styled(Heading)`
  max-width: 100%;

  ${s({
    typography: { xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' },
    mb: 0,
    ml: 4,
  })}
`

type HeaderProps = {
  onClose: () => void
  hideClearButton: boolean
}

export const Header: FC<HeaderProps> = ({ onClose, hideClearButton }) => {
  const localiseText = useFindLocaleText()

  return (
    <StyledHeader>
      <IconButton
        icon={IconSystemCross}
        label={localiseText('find.close_filters_menu')}
        onClick={onClose}
      />
      <StyledTitleText level="h2">
        {localiseText('find.filters')}
      </StyledTitleText>
      <ClearButton
        level={0}
        hide={hideClearButton}
        source={FilterSource.FiltersMenu}
      />
    </StyledHeader>
  )
}
