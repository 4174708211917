import {
  createGenericSelectContentEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import {
  SearchStoreState,
  TrackingActionTypes,
  TrackingActionsEnum,
  TrackingPayload,
} from '../types'
import { getTrackingLabelForSortOrder } from '../../utils/getTrackingLabel'
import { FilterCategory } from '../../services/types/services'
import { GALLERY_PAGE_TYPE, SEARCH_PAGE_TYPE } from '../../constants'
import { createSearchGAEvent } from '../../analytics/createSearchGAEvent'
import { createViewGalleryResultsGAEvent } from '../../analytics/createViewGalleryResultsGAEvent'
import { QueryProductsProps } from '../slices/products'
import { trackLoadedProductsEvent } from './events'

export const trackEvent = ({
  action,
  oldValues,
  newValues,
  payload,
}: {
  action: TrackingActionTypes
  payload: TrackingPayload
  oldValues: SearchStoreState
  newValues: SearchStoreState
}) => {
  switch (action) {
    case TrackingActionsEnum.toggleFiltersMenu:
      trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: `${newValues.pageContext.type} action`,
          eventAction: newValues.filtersMenuOpen
            ? 'filters opened'
            : 'filters closed',
          eventLabel: newValues.pageContext.title,
        }),
      )
      break

    case TrackingActionsEnum.toggleFiltersCategory:
      trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: `${newValues.pageContext.type} action`,
          eventAction:
            newValues.selectedCategories.length >
            oldValues.selectedCategories.length
              ? 'expand'
              : 'collapse',
          eventLabel:
            newValues.selectedCategories.length >
            oldValues.selectedCategories.length
              ? newValues.selectedCategories[
                  newValues.selectedCategories.length - 1
                ].label
              : oldValues.selectedCategories[
                  oldValues.selectedCategories.length - 1
                ]?.label || '',
        }),
      )
      break

    case TrackingActionsEnum.clearFilters:
      trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: `${newValues.pageContext.type} action`,
          eventAction: 'clear filters',
          eventLabel: (payload[0] as { category: FilterCategory }).category
            ? `${
                (payload[0] as { category: FilterCategory }).category.label
              } category`
            : 'all',
        }),
      )
      break

    case TrackingActionsEnum.sortBy:
      trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: `${newValues.pageContext.type} action`,
          eventAction: 'sort by',
          eventLabel: getTrackingLabelForSortOrder(payload[0] as string),
        }),
      )
      break

    case TrackingActionsEnum.loadInitialData:
      if (newValues.shouldUseSearchStoreRefactor) {
        if (newValues.pageContext.type === SEARCH_PAGE_TYPE) {
          trackGAEvent(
            createSearchGAEvent({
              correctedSearchTerm:
                newValues.searchContext.correctedSearchTerm || '',
              department: newValues.searchContext.departments,
              filters: [
                ...newValues.preAppliedFilters,
                ...newValues.selectedFilters.map(f => ({
                  key: f.id,
                  group: f.parent,
                })),
              ],
              filteringEvent: newValues.filteringEvent,
              numberOfResults: newValues.results.totalCount,
              searchTerm: newValues.searchContext.searchTerm,
              sort: newValues.sortValue,
              selectedSuggestion: newValues.searchContext.selectedSuggestion,
            }),
          )
          trackLoadedProductsEvent({
            results: newValues.results,
            searchContext: newValues.searchContext,
            preAppliedFilters: newValues.preAppliedFilters,
            selectedFilters: newValues.selectedFilters,
            pageContext: newValues.pageContext,
            lastLoadedPage: newValues.lastLoadedPage,
            filteringEvent: newValues.filteringEvent,
            isPrevious: payload[0] as boolean,
          })
        }

        if (newValues.pageContext.type === GALLERY_PAGE_TYPE) {
          trackGAEvent(
            createViewGalleryResultsGAEvent({
              department: newValues.searchContext.departments,
              filters: [
                ...newValues.preAppliedFilters,
                ...newValues.selectedFilters.map(f => ({
                  key: f.id,
                  group: f.parent,
                })),
              ],
              filteringEvent: newValues.filteringEvent,
              numberOfResults: newValues.results.totalCount,
              pageTitle: newValues.pageContext.title,
              sort: newValues.sortValue,
            }),
          )
          trackLoadedProductsEvent({
            results: newValues.results,
            searchContext: newValues.searchContext,
            preAppliedFilters: newValues.preAppliedFilters,
            selectedFilters: newValues.selectedFilters,
            pageContext: newValues.pageContext,
            lastLoadedPage: newValues.lastLoadedPage,
            filteringEvent: newValues.filteringEvent,
            isPrevious: payload[0] as boolean,
          })
        }
      }

      break

    case TrackingActionsEnum.queryProducts:
      if (!(payload[0] as QueryProductsProps).isLoadMore) {
        if (newValues.pageContext.type === SEARCH_PAGE_TYPE) {
          const selectedFilters =
            (payload[0] as QueryProductsProps).filters ||
            newValues.selectedFilters
          trackGAEvent(
            createSearchGAEvent({
              correctedSearchTerm:
                newValues.searchContext.correctedSearchTerm || '',
              department: newValues.searchContext.departments,
              filters: selectedFilters.map(f => ({
                key: f.id,
                group: f.parent,
              })),
              filteringEvent: newValues.filteringEvent,
              numberOfResults: newValues.results.totalCount,
              searchTerm: newValues.searchContext.searchTerm,
              sort: newValues.sortValue,
              selectedSuggestion: newValues.searchContext.selectedSuggestion,
            }),
          )

          trackLoadedProductsEvent({
            results: newValues.results,
            searchContext: newValues.searchContext,
            preAppliedFilters: newValues.preAppliedFilters,
            selectedFilters: newValues.selectedFilters,
            pageContext: newValues.pageContext,
            lastLoadedPage: newValues.lastLoadedPage,
            filteringEvent: newValues.filteringEvent,
            isPrevious: payload[0] as boolean,
          })
        }

        if (newValues.pageContext.type === GALLERY_PAGE_TYPE) {
          const selectedFilters =
            (payload[0] as QueryProductsProps).filters ||
            newValues.selectedFilters
          trackGAEvent(
            createViewGalleryResultsGAEvent({
              department: newValues.searchContext.departments,
              filters: [
                ...newValues.preAppliedFilters,
                ...selectedFilters.map(f => ({
                  key: f.id,
                  group: f.parent,
                })),
              ],
              filteringEvent: newValues.filteringEvent,
              numberOfResults: newValues.results.totalCount,
              pageTitle: newValues.pageContext.title,
              sort: newValues.sortValue,
            }),
          )

          trackLoadedProductsEvent({
            results: newValues.results,
            searchContext: newValues.searchContext,
            preAppliedFilters: newValues.preAppliedFilters,
            selectedFilters: newValues.selectedFilters,
            pageContext: newValues.pageContext,
            lastLoadedPage: newValues.lastLoadedPage,
            filteringEvent: newValues.filteringEvent,
            isPrevious: payload[0] as boolean,
          })
        }
      }
      break

    case TrackingActionsEnum.loadMoreProducts:
      trackLoadedProductsEvent({
        results: newValues.results,
        searchContext: newValues.searchContext,
        preAppliedFilters: newValues.preAppliedFilters,
        selectedFilters: newValues.selectedFilters,
        pageContext: newValues.pageContext,
        lastLoadedPage: newValues.lastLoadedPage,
        filteringEvent: newValues.filteringEvent,
        isPrevious: payload[0] as boolean,
      })
      break
  }
}
