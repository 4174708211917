import React, { forwardRef, PropsWithChildren } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { CarouselItem as CarouselElement } from '@moonpig/launchpad-components'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import {
  CAROUSEL_ITEM_HEIGHT_PX,
  CAROUSEL_ITEM_HEIGHT_PX_VARIANT,
} from '../constants'

const StyledCarouselItem = styled(CarouselElement)<{
  useDropdownFilters: boolean
}>`
  height: ${({ useDropdownFilters }) =>
    useDropdownFilters
      ? CAROUSEL_ITEM_HEIGHT_PX_VARIANT
      : CAROUSEL_ITEM_HEIGHT_PX};
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CarouselItem = forwardRef<
  HTMLLIElement,
  PropsWithChildren<unknown>
>(({ children }, ref) => {
  const useDropdownFilters =
    useExperimentString('search-use-dropdown-filters-v2', {
      fallback: 'disabled',
    })?.toLocaleLowerCase() === 'enabled'

  return (
    <StyledCarouselItem
      tabIndex={-1}
      ref={ref}
      useDropdownFilters={useDropdownFilters}
    >
      {children}
    </StyledCarouselItem>
  )
})
