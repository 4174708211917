import React, { useState } from 'react'
import { Box, IconButton, Text } from '@moonpig/launchpad-components'
import {
  IconSystemCrossSmall,
  IconSystemThumbsUp,
  IconSystemThumbsDown,
} from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue, spacingPx } from '@moonpig/launchpad-theme'
import { useProductsLocaleText } from '../../locale'
import { FEEDBACK_BUTTON_SIZE_PX } from './constants'

const StyledCustomerSatisfactionTile = styled(Box)`
  ${s({
    mb: { xs: 6, md: 4 },
    width: '100%',
  })}
`

const StyledWrapper = styled(Box)`
  ${s({
    py: { xs: 8, md: 6 },
    mx: { xs: 3, md: 4, lg: 5, xxl: 9 },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: { xs: 'start', md: 'center' },
    bgcolor: 'colorBackground02',
    borderRadius: 2,
    border: '1px',
    borderColor: 'colorBorder03',
  })}
`

const StyledContent = styled.div`
  ${s({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    position: 'relative',
    ml: 12,
  })}
  gap: ${spacingPx(8)};
`
const StyledButtonsContainer = styled(Box)`
  ${s({
    flex: 1 / 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  })}
  gap: ${spacingPx(4)};
`

const StyledIconButtonClose = styled(IconButton)`
  flex: 1/8;
  ${s({
    color: 'colorInteractionIcon',
    mt: { xs: -8, md: 0 },
  })}
`

const FeedbackButton = styled(IconButton)`
  ${s({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: FEEDBACK_BUTTON_SIZE_PX,
    width: FEEDBACK_BUTTON_SIZE_PX,
    padding: 5,
    border: '1px',
    borderColor: 'colorBorder03',
    bgcolor: 'colorBlack00',
  })}
  cursor: pointer;
  border-radius: 50%;

  & + input[type='radio']:active {
    outline: 2px solid ${colorValue('colorInteractionFocusIndicator')};
    outline-offset: 2px;
  }
`

const StyledThumbsUpButton = styled(FeedbackButton)`
  ${s({
    color: 'colorFeedbackSuccess',
  })}

  &:hover, &:active {
    ${s({
      bgcolor: 'colorBackgroundSuccess',
    })}
  }

  &:focus {
    ${s({
      bgcolor: 'blackColor30',
    })}
    outline: 2px solid ${colorValue('colorInteractionFocusIndicator')};
  }
`

const StyledThumbsDownButton = styled(FeedbackButton)`
  ${s({
    color: 'colorFeedbackError',
  })}
  border-radius: 50%;

  &:hover,
  &:active {
    ${s({
      bgcolor: 'colorBackgroundError',
    })}
  }

  &:focus {
    ${s({
      bgcolor: 'blackColor30',
    })}
    outline: 2px solid ${colorValue('colorInteractionFocusIndicator')};
  }
`

const StiledTitle = styled(Text)`
  ${s({
    typography: {
      xs: 'typeDisplay06',
      md: 'typeDesktopDisplay05',
    },
    textAlign: 'center',
  })}
`

enum FeedbackResponse {
  thumbsUp = 'thumbs up',
  thumbsDown = 'thumbs down',
}

export const CustomerSatisfactionTile = () => {
  const t = useProductsLocaleText()
  const [customerSelection, setCustomerSelection] =
    useState<FeedbackResponse | null>(null)

  const handleOnClick = (selection: FeedbackResponse) => {
    setCustomerSelection(selection)
  }

  return (
    <StyledCustomerSatisfactionTile data-testid="customer-satisfaction-tile">
      <StyledWrapper>
        <StyledContent>
          {customerSelection ? (
            <StiledTitle>
              {t('customer_staisfaction_tile.thankyou')}
            </StiledTitle>
          ) : (
            <>
              <StiledTitle>
                {t('customer_staisfaction_tile.question')}
              </StiledTitle>
              <StyledButtonsContainer>
                <StyledThumbsUpButton
                  icon={IconSystemThumbsUp}
                  width={24}
                  height={24}
                  label={t('customer_staisfaction_tile.yes')}
                  onClick={() => handleOnClick(FeedbackResponse.thumbsUp)}
                />
                <StyledThumbsDownButton
                  icon={IconSystemThumbsDown}
                  width={24}
                  height={24}
                  label={t('customer_staisfaction_tile.no')}
                  onClick={() => handleOnClick(FeedbackResponse.thumbsDown)}
                />
              </StyledButtonsContainer>
            </>
          )}
        </StyledContent>
        <StyledIconButtonClose
          width="24px"
          height="24px"
          label={t('customer_staisfaction_tile.close')}
          icon={IconSystemCrossSmall}
        />
      </StyledWrapper>
    </StyledCustomerSatisfactionTile>
  )
}
