import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql'
import { ApplicableFilter, FilterItem } from '../../services/types/services'

const filterOrderByDepartment: Record<
  | DepartmentsEnum.ALL_CARDS
  | DepartmentsEnum.ALL_GIFTS
  | DepartmentsEnum.ALL_FLOWERS_AND_PLANTS,
  string[]
> = {
  [DepartmentsEnum.ALL_CARDS]: [
    'whos-it-for',
    'newia-occasion',
    'photo-upload-count-group',
    'birthday-milestones',
    'newia-age',
    'photo-cards',
    'not-rude',
  ],
  [DepartmentsEnum.ALL_GIFTS]: [
    'whos-it-for',
    'newia-occasion',
    'product-type',
    'newia-age',
    'price',
    'personalisable',
  ],
  [DepartmentsEnum.ALL_FLOWERS_AND_PLANTS]: [
    'newia-occasion',
    'price',
    'newia-gift-sets-hampers-letterbox',
  ],
}

export const orderQuickFilters = (
  filters: FilterItem[],
  useProductTypeV1: boolean,
  department: DepartmentsEnum,
) => {
  if (!useProductTypeV1) {
    return filters.filter(
      f =>
        (f.__typename === 'FilterCategory' ||
          f.__typename === 'FilterToggle' ||
          f.__typename === 'FilterInvertedToggle') &&
        f.isQuickFilter &&
        f.id !== 'product-type',
    ) as ApplicableFilter[]
  }

  const order =
    filterOrderByDepartment[
      department as keyof typeof filterOrderByDepartment
    ] || /* istanbul ignore next */ []

  const filterToReturn = (
    filters.filter(
      f =>
        (f.__typename === 'FilterCategory' ||
          f.__typename === 'FilterToggle' ||
          f.__typename === 'FilterInvertedToggle') &&
        f.isQuickFilter &&
        order.includes(f.id),
    ) as ApplicableFilter[]
  ).sort((a, b) => {
    return order.indexOf(a.id) - order.indexOf(b.id)
  })

  return filterToReturn
}
