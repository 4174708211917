import { Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { QuickSortBy } from './QuickSortBy'

const StyledBox = styled(Box)`
  ${s({
    display: { xs: 'none', lg: 'flex' },
    alignItems: 'center',
    position: { xs: 'absolute', lg: 'relative' },
    right: 0,
    top: 0,
    zIndex: 1,
  })}
`

type Props = {
  onClick: () => void
}

export const DesktopQuickSortBy: FC<Props> = ({ onClick }) => {
  return (
    <StyledBox data-testid="desktop-quick-sort-by">
      <QuickSortBy onClick={onClick} />
    </StyledBox>
  )
}
