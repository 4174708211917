import React, { FC, ReactNode, useMemo, useState } from 'react'
import { Drawer } from '@moonpig/web-shared-components'
import { dispatcherContext } from './Context'
import { Dispatcher, ShowDrawer } from './types'

type State = {
  isOpen: boolean
  title: string
  triggerRef: React.RefObject<HTMLButtonElement> | undefined
  header:
    | {
        left?: ReactNode | 'close-button'
        right?: ReactNode | 'close-button'
      }
    | undefined
  footer: JSX.Element | undefined
  body: (React.JSX.Element | null)[]
  onClose: () => void
}

const EMPTY_STATE = {
  isOpen: false,
  title: '',
  triggerRef: undefined,
  header: undefined,
  footer: undefined,
  body: [],
  onClose: /* istanbul ignore next */ () => {},
}

export const DrawerProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<State>(EMPTY_STATE)

  const actions: Dispatcher = useMemo(
    () => ({
      show: (params: ShowDrawer) => {
        setState({ ...params, isOpen: true })
      },
      close: () => {
        setState({ ...state, isOpen: false })
      },
      update: (
        body: (React.JSX.Element | null)[],
        footer: JSX.Element | undefined,
        header: {
          left: string
          right: React.JSX.Element
        },
      ) => {
        setState({
          ...state,
          body,
          footer,
          header,
        })
      },
    }),
    [state],
  )

  return (
    <dispatcherContext.Provider value={actions}>
      {children}
      {state && (
        <Drawer
          data-testid="filter-drawer"
          ariaLabel={`${state.title} filters`}
          anchor="bottom"
          isOpen={state.isOpen}
          offset={48}
          onClosed={() => {
            setState({ ...state, isOpen: false })
            state.onClose()
          }}
          triggerRef={state.triggerRef}
          header={state.header}
          title={state.title}
          footer={state.footer}
        >
          {state.body}
        </Drawer>
      )}
    </dispatcherContext.Provider>
  )
}
