import { Box } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { breakpointUp, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Carousel } from '@moonpig/web-shared-components'
import { useFlag } from '@moonpig/web-explore-flags'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import { useSearchStore } from '../../store/SearchStore'
import { DropDownMenu } from './DropDownMenu'
import { FilterCategory, FilterToggle } from '../../services/types/services'
import { QuickFilterToggle } from './QuickFilterToggle/QuickFilterToggle'
import { QuickSortBy } from './QuickSortBy/QuickSortBy'
import { DesktopQuickSortBy } from './QuickSortBy/DesktopQuickSortBy'

import { orderQuickFilters } from './orderQuickFilters'

const StyledQuickFiltersBar = styled(Box)`
  ${s({
    bgcolor: 'colorBackground01',
    borderBottom: 1,
    borderColor: 'colorBorder04',
    px: { xs: 4, md: 8, lg: 10, xxl: 12 },
  })}

  ${breakpointUp('md')} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${spacingPx(12)};
  }
`

export const QuickFilters: FC<{ departments: DepartmentsEnum[] }> = ({
  departments,
}) => {
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const toggleFiltersMenu = useSearchStore(store => store.toggleFiltersMenu)
  const filters = useSearchStore(store => store.filters)
  const toggleFilter = useSearchStore(store => store.toggleFilter)
  const resultsLoading = useSearchStore(store => store.loading)
  const results = useSearchStore(store => store.results)
  const toggleFiltersCategory = useSearchStore(
    store => store.toggleFiltersCategory,
  )
  const clearFilters = useSearchStore(store => store.clearFilters)

  const useProductTypeV1 = useFlag('search-product-type-v1')

  const department = getParentDepartment(departments[0])
  const orderedFilters = orderQuickFilters(
    filters,
    useProductTypeV1,
    department,
  )

  return (
    <StyledQuickFiltersBar data-testid="dropdown-filters-v2">
      <Carousel itemsGap={{ xs: 0 }} noSpacing>
        {(
          orderedFilters.filter(
            f => f.__typename === 'FilterCategory',
          ) as FilterCategory[]
        ).map(filterCategory => {
          return (
            <DropDownMenu
              filterCategory={filterCategory}
              selectedFilters={selectedFilters}
              key={`${filterCategory.id}-quick-filter`}
              onChange={() => toggleFiltersCategory(filterCategory)}
              toggleFilter={toggleFilter}
              toggleFilterCategory={toggleFiltersCategory}
              count={results.totalCount}
              loading={resultsLoading}
              clearFilters={clearFilters}
            />
          )
        })}
        {(
          orderedFilters.filter(
            f =>
              f.__typename === 'FilterToggle' ||
              f.__typename === 'FilterInvertedToggle',
          ) as FilterToggle[]
        ).map(filter => {
          return (
            <QuickFilterToggle
              toggleFilter={filter}
              key={`${filter.id}-quick-filter-toggle`}
            />
          )
        })}
        <Box
          display={{ xs: 'flex', md: 'flex', lg: 'none' }}
          alignItems="center"
          data-testid="mobile-quick-sort-by"
        >
          <QuickSortBy onClick={() => toggleFiltersMenu('sort')} />
        </Box>
      </Carousel>
      <DesktopQuickSortBy onClick={() => toggleFiltersMenu('sort')} />
    </StyledQuickFiltersBar>
  )
}
