import { useEffect } from 'react'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { DepartmentsEnum, SortOrder } from '@moonpig/web-explore-types-graphql'
import { createSearchGAEvent } from '../analytics/createSearchGAEvent'
import { createViewGalleryResultsGAEvent } from '../analytics/createViewGalleryResultsGAEvent'
import { GALLERY_PAGE_TYPE, SEARCH_PAGE_TYPE } from '../constants'
import { SearchFilter, SearchProduct } from '../services'
import { SelectedSuggestion } from '../components/types'
import { FilteringEvent } from '../types'

export const useTrackSearchEvent: (props: {
  correctedSearchTerm: string
  department: DepartmentsEnum[]
  filters: SearchFilter[]
  filteringEvent?: FilteringEvent
  numberOfResults: number
  searchTerm: string
  sort: SortOrder
  selectedSuggestion?: SelectedSuggestion
  pageTitle?: string
  pageType: string
  shouldUseSearchStore: boolean
  products: SearchProduct[]
}) => void = ({
  correctedSearchTerm,
  department,
  filters,
  filteringEvent,
  numberOfResults,
  searchTerm,
  sort,
  selectedSuggestion,
  pageTitle,
  pageType,
  shouldUseSearchStore,
  products,
}) => {
  const { trackGAEventOnce } = useTrackGAEventOnce()

  useEffect(() => {
    if (!shouldUseSearchStore && pageType === SEARCH_PAGE_TYPE) {
      trackGAEventOnce(
        createSearchGAEvent({
          correctedSearchTerm,
          department,
          filters,
          filteringEvent,
          numberOfResults,
          searchTerm,
          sort,
          selectedSuggestion,
        }),
      )
    }

    if (!shouldUseSearchStore && pageType === GALLERY_PAGE_TYPE) {
      trackGAEventOnce(
        createViewGalleryResultsGAEvent({
          department,
          filters,
          filteringEvent,
          numberOfResults,
          pageTitle: pageTitle ?? /* istanbul ignore next */ '',
          sort,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, sort])
}
