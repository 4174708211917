import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemCrossSmall } from '@moonpig/launchpad-assets'
import { spacingPx } from '@moonpig/launchpad-theme'

const ICON_SIZE = spacingPx(8)

interface ChipProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dismissible?: boolean
  selected: boolean
}

const StyledIconCross = styled(IconSystemCrossSmall)`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  ${s({
    ml: 4,
    borderRadius: 7,
    bgcolor: 'colorInteractionSelectedState',
    color: 'colorBackground03',
  })}
`

const StyledChip = styled.button<Pick<ChipProps, 'selected'>>`
  transition: none;
  ${s({
    border: 0,
    paddingY: '10px',
    paddingX: '12px',
    borderRadius: 6,
    typography: 'typeBodyLabel',
  })}

  ${({ selected }) =>
    s({
      bgcolor: selected ? 'colorBackground03' : 'colorBorder04',
      color: selected ? 'colorInteractionSelectedState' : 'colorTextHeadline',
    })}

  &:not(:disabled) {
    &:hover {
      ${s({
        border: 0,
        paddingY: '10px',
        paddingX: '12px',
        bgcolor: 'colorBlack10',
        typography: 'typeBodyLabel',
      })}

      ${({ selected }) =>
        s({
          color: selected
            ? 'colorInteractionIcon'
            : 'colorInteractionSelectedState',
          bgcolor: selected ? 'colorBackground03' : 'colorBlack10',
        })}

      .dismiss-icon {
        ${({ selected }) =>
          s({
            bgcolor: selected
              ? 'colorInteractionIcon'
              : 'colorInteractionSelectedState',
          })}
      }
    }

    &:focus-visible {
      text-decoration: none;
      outline: none;

      ${s({
        boxShadow: 5,
        border: 0,
        paddingY: '10px',
        paddingX: '12px',
        bgcolor: 'colorBlack10',
        typography: 'typeBodyLabel',
      })}

      ${({ selected }) =>
        s({
          color: selected
            ? 'colorInteractionIcon'
            : 'colorInteractionSelectedState',
          bgcolor: selected ? 'colorBackground03' : 'colorBlack10',
        })}

      .dismiss-icon {
        ${({ selected }) =>
          s({
            bgcolor: selected
              ? 'colorInteractionIcon'
              : 'colorInteractionSelectedState',
          })}
      }
    }
  }
`

const StyledSpan = styled.span`
  display: flex;
  text-align: center;
`

export const Chip: React.FC<React.PropsWithChildren<ChipProps>> = ({
  children,
  dismissible,
  selected,
  ...rest
}) => {
  const showDismissButton = selected && dismissible
  return (
    <StyledChip selected={selected} {...rest}>
      <StyledSpan>
        {children}
        {showDismissButton && <StyledIconCross className="dismiss-icon" />}
      </StyledSpan>
    </StyledChip>
  )
}
