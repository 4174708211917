import React, { FC } from 'react'
import { Accordion } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { FilterCategory, FilterItem } from '../../../../services/types/services'
import { mapFilterItem } from '../../../../utils/GalleryFiltering/mappings'

const StyledAccordion = styled(Accordion)`
  border: none;
`

const getClickedCategoryDetails = ({
  updatedSections,
  currentSections,
}: {
  updatedSections: string[]
  currentSections: string[]
}): { action: string; label: string } => {
  if (updatedSections.length > currentSections.length) {
    const openSection = updatedSections.filter(
      section => !currentSections.includes(section),
    )

    return {
      action: 'expand',
      label: openSection[0],
    }
  }

  const closedSection = currentSections.filter(
    section => !updatedSections.includes(section),
  )
  return {
    action: 'collapse',
    label: closedSection[0],
  }
}

type Props = {
  category: FilterCategory
  level: number
  onClickItem: (filterItem: FilterItem) => void
  onClickCategoryItem: ({
    action,
    label,
  }: {
    action: string
    label: string
  }) => void
}

export const AccordionMenu: FC<Props> = ({
  category,
  level,
  onClickItem,
  onClickCategoryItem,
}) => {
  const [openSections, setOpenSections] = React.useState<string[]>([])

  return (
    <StyledAccordion
      type="multiple"
      onValueChange={(updatedSections: string[]) => {
        const clickedCategoryDetails = getClickedCategoryDetails({
          updatedSections,
          currentSections: openSections,
        })

        onClickCategoryItem(clickedCategoryDetails)
        setOpenSections(updatedSections)
      }}
    >
      {category.children.map(filterItem =>
        mapFilterItem({
          filterItem,
          onClick: onClickItem,
          level,
        }),
      )}
    </StyledAccordion>
  )
}
