import { useRouter } from '@moonpig/web-core-routing'
import { useEffect } from 'react'
import { useFlag } from '@moonpig/web-explore-flags'
import { useProductListingPageContext } from './productListingPageContext'
import { useSearchStore } from '../store/SearchStore'

export const useResetProductListing = (offset: number) => {
  const { onRouteChangeStart } = useRouter()
  const [{ initialOffset: currentInitialOffset }, dispatch] =
    useProductListingPageContext()
  const shouldUseSearchStore = useFlag('search-state-management-refactor')
  const resetInitialOffset = useSearchStore(store => store.resetInitialOffset)
  const initialOffsetFromStore = useSearchStore(store => store.initialOffset)

  useEffect(
    () =>
      onRouteChangeStart(() => {
        if (shouldUseSearchStore) {
          if (offset !== initialOffsetFromStore) {
            resetInitialOffset()
          }
        } else if (offset !== currentInitialOffset) {
          dispatch({
            type: 'RESET',
            payload: {
              initialOffset: offset,
            },
          })
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, onRouteChangeStart],
  )
}
