import { css } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  FILTERS_MENU_BUTTON_HEIGHT_PX,
  FILTERS_MENU_BUTTON_HEIGHT_PX_VARIANT,
} from '../../../FiltersCarousel/constants'

export const styledToggleVariantStyles = css<{ filtersApplied: boolean }>`
  ${({ filtersApplied }) =>
    s({
      bgcolor: 'colorBackground01',
      border: 2,
      px: 6,
      py: 4,
      borderColor: filtersApplied
        ? 'colorInteractionSelectedState'
        : 'colorBorder03',
      color: filtersApplied
        ? 'colorInteractionSelectedState'
        : 'colorInteractionIcon',
      boxShadow: 0,
      borderRadius: 6,
      minHeight: FILTERS_MENU_BUTTON_HEIGHT_PX_VARIANT,
    })}

  &:not(:disabled) {
    &:hover {
      text-decoration: none;
      outline: none;

      ${({ filtersApplied }) =>
        s({
          boxShadow: 0,
          borderColor: filtersApplied
            ? 'colorInteractionSelectedState'
            : 'colorBlack30',
          bgcolor: filtersApplied ? 'colorBackground03' : 'colorBorder04',
        })}
    }

    &:focus {
      text-decoration: none;
      outline: none;
      ${({ filtersApplied }) =>
        s({
          boxShadow: 5,
          borderColor: filtersApplied
            ? 'colorInteractionSelectedState'
            : 'colorBlack30',
          bgcolor: filtersApplied ? 'colorBackground03' : 'colorBorder04',
        })}
    }
  }
`

export const styledToggleStyles = css`
  ${s({
    bgcolor: 'colorBackground01',
    border: 2,
    pl: 5,
    py: 5,
    borderColor: 'colorBorder05',
    color: 'colorInteractionIcon',
    boxShadow: 0,
    height: FILTERS_MENU_BUTTON_HEIGHT_PX,
    borderRadius: 6,
  })}

  &:not(:disabled) {
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      ${s({
        boxShadow: 0,
        border: 2,
        color: 'colorInteractionButtonHover',
        bgcolor: 'colorBackground06',
        borderColor: 'colorInteractionButtonHover',
      })}
    }
  }
`
