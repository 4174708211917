import { useGetBreakpoint } from '@moonpig/web-shared-utils'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { getTilesPerRow } from './getTilesPerRow'

export const useNumberOfTilesPerRow = (useExperiments = true) => {
  const breakpoint = useGetBreakpoint()
  const showCardProductTileVariant =
    useExperimentString('search-product-tile-ux', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  const currentTilesPerRow = getTilesPerRow(
    breakpoint,
    useExperiments && showCardProductTileVariant,
  )

  return currentTilesPerRow
}
