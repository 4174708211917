import React, { FC, PropsWithChildren } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { useTrapFocusWithin } from '../../../../utils/useTrapFocusWithin/useTrapFocusWithin'

const StyledPanel = styled(Box)<{ level: number }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  ${({ level }) => `z-index: ${level};`}
`

type Props = {
  level: number
  id: string
}

export const Panel: FC<PropsWithChildren<Props>> = ({
  children,
  level,
  id,
}) => {
  const trappingAreaRef = useTrapFocusWithin()

  return (
    <StyledPanel
      data-testid="panel"
      level={level}
      id={id}
      ref={trappingAreaRef}
    >
      {children}
    </StyledPanel>
  )
}
