import { StateCreator } from 'zustand'
import {
  SearchStoreState,
  TrackingActionTypes,
  TrackingPayload,
} from '../types'
import { trackEvent } from './trackEvent'

const trackFunction = ({
  key,
  value,
  get,
}: {
  key: TrackingActionTypes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  get: () => SearchStoreState
}) => {
  return async (...args: TrackingPayload) => {
    const oldValues = get()
    const ret = await value(...args)
    const newValues = get()
    trackEvent({ action: key, payload: args, oldValues, newValues })
    return ret
  }
}

type EnhanceValueParams<T> = {
  key: TrackingActionTypes
  value: T
  get: () => SearchStoreState
}

const enhanceValue = <T>({ key, value, get }: EnhanceValueParams<T>): T => {
  if (typeof value === 'function') {
    return trackFunction({ key, value, get }) as T
  }
  return value
}

export const trackingMiddleware =
  <T extends SearchStoreState>(config: StateCreator<T>): StateCreator<T> =>
  (set, get, api) => {
    const result = config(set, get, api)

    return Object.fromEntries(
      Object.entries(result).map(([key, value]) => {
        const enhancedValue = enhanceValue({
          key: key as TrackingActionTypes,
          value,
          get,
        })
        return [key, enhancedValue]
      }),
    ) as T
  }
