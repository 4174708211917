import { create } from 'zustand'
import type { SearchStoreState } from './types'
import { trackingMiddleware } from './middleware/Tracking'
import {
  createProductsSlice,
  createFiltersSlice,
  createContextSlice,
  createSharedSlice,
} from './slices'

export const useSearchStore = create<SearchStoreState>(
  trackingMiddleware((set, get, api) => ({
    ...createSharedSlice(set, get, api),
    ...createContextSlice(set, get, api),
    ...createFiltersSlice(set, get, api),
    ...createProductsSlice(set, get, api),
  })),
)
